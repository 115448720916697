<template>
  <div>
    <b-row>
      <b-col class="col-md-12 col-lg-12">
        <b-row class="pb-3">
          <b-col class="col-md-6 col-lg-6">
            <h4>Groups List</h4>
          </b-col>
          <b-col class="col-md-6 col-lg-6 text-right">
            <b-button
              v-if="user.role!='readonly'"
              size="sm"
              variant="primary"
              v-b-modal.modal-new-group
            >Add New Group</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset-md="6" md="6" class="mb-2">
            <b-form-group label-cols-sm="3" class="mb-0">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          id="groups-list"
          striped
          hover
          small
          show-empty
          empty-text="No data available in table"
          :items="groups"
          :fields="columns"
          :busy="isBusy"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered" >
          <template v-slot:cell(action)="data">
            <font-awesome-icon icon="chart-line"
              v-if="user.role=='admin'"
              id="statsButton"
              @click="getGroupStatistic(data.item)"
              class="mr-1 action-icon"/>
            <font-awesome-icon :icon="isGroupActive(data.item) ? 'lock' : 'lock-open'"
              v-if="user.role=='admin'"
              id="toggleStateButton"
              @click="setSelectedGroup(data.item)"
              class="mr-1 action-icon"
              :title="isGroupActive(data.item) ? 'Disable group' : 'Enable group'"
              v-b-modal.modal-group-toggle-state/>
            <font-awesome-icon icon="edit"
              v-if="user.role!='readonly'"
              id="editButton"
              @click="setSelectedGroup(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-edit-group/>
            <font-awesome-icon icon="trash-alt"
              v-if="user.role!='readonly'"
              id="disableEnableButton"
              @click="setSelectedGroup(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-group-delete />
          </template>
          <template v-slot:cell(state)="data">
            {{ data.item.state }} <span class="badge badge-warning">Legacy</span>
          </template>
          <!-- <template v-slot:cell(company)="data">
            <router-link :to="{ path: `/company/${data.item.company.id}` }" target="_blank">
              {{ data.item.company.name }}
            </router-link>
          </template> -->
        </b-table>
        <b-row>
          <div class="col-md-6 col-lg-6">
            <label>Show </label>
            <b-form-select v-model="perPage" class="select-xs">
              <option value=10>10</option>
              <option value=25>25</option>
              <option value=50>50</option>
              <option value=100>100</option>
            </b-form-select>
            <span>entries</span>
          </div>
          <div class="col-md-6 col-lg-6">
            <b-pagination
              v-model="currentPage"
              :total-rows="groups.length"
              :per-page="perPage"
              aria-controls="groups-list"
              align="right"
              v-bind:hide-goto-end-buttons="true"
            ></b-pagination>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-modal
        id="modal-new-group"
        ref="modalAdd"
        title="New Group"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @shown="handleNewGroupModalShown"
        @ok="handleNewOk"
      >
        <group-form ref="formNew" @submit.stop.prevent="handleSubmit"></group-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        id="modal-edit-group"
        ref="modalEdit"
        title="Edit Group"
        v-bind:ok-only="true"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @shown="handleEditGroupModalShown"
      >
        <template #modal-footer>
          <b-row class="w-100">
            <b-col class="col-2 text-left group-edit-col-left">
              <b-button
                class="btn-block group-edit-btn"
                size="md"
                variant="primary"
                @click="handleEditSave">
              Save
              </b-button>
            </b-col>
            <b-col class="col-2 text-center group-edit-col">
              <b-button
                class="btn-block group-edit-btn"
                size="md"
                variant="primary"
                @click="handleEditApply">
              Apply
              </b-button>
            </b-col>
            <b-col class="col-2 text-center group-edit-col">
              <b-button
                class="btn-block group-edit-btn"
                size="md"
                variant="primary"
                @click="handleEditClose">
              Close
              </b-button>
            </b-col>
            <b-col class="col-2 text-center group-edit-col">
              <b-button
                class="btn-block group-edit-btn"
                size="md"
                variant="secondary"
                @click="handlePing">
              Ping
              </b-button>
            </b-col>
            <b-col class="col-2 text-center group-edit-col">
              <b-button
                class="btn-block group-edit-btn"
                size="md"
                variant="secondary"
                @click="handleTraceRoute">
              TRoute
              </b-button>
            </b-col>
            <b-col class="col-2 text-right group-edit-col-rigth">
              <b-button
                class="btn-block group-edit-btn"
                size="md"
                variant="secondary"
                @click="handleRoutingTables">
              RTables
              </b-button>
            </b-col>
          </b-row>
        </template>
        <group-form
          ref="formEdit"
          is-edit-form="true"
          :input-customer-id="customerId"
          :group-id="selectedGroup.id"
          @submit.stop.prevent="handleSubmit"></group-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        id="modal-group-delete"
        ref="modalDelete"
        title="Delete Group"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="disableGroup(selectedGroup.id)"
      >
        <p>Group {{ selectedGroup ? selectedGroup.name : '' }} will be deleted. Do you want to proceed?</p>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
          id="modal-group-toggle-state"
          ref="modalToggleState"
          title="Toggle group status"
          v-bind:no-close-on-backdrop="true"
          v-bind:no-close-on-esc="true"
          @ok="toggleGroupState(selectedGroup)"
      >
        <p>
          Status of group {{ selectedGroup ? selectedGroup.name : '' }} will be
          changed to {{ isGroupActive(selectedGroup) ? this.states.inactive : this.states.active }}. Do you want to proceed?
        </p>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
          id="modal-group-stats"
          ref="modalStats"
          title="Group statistics"
          v-bind:no-close-on-backdrop="true"
          v-bind:no-close-on-esc="true">
        <b-table stacked :items="statistics">
          <template v-slot:cell(bandwidth)="data">
            <b>RX</b> - {{ data.item.bandwidth.RX }}<br>
            <b>TX</b> - {{ data.item.bandwidth.TX }}<br>
            <b>Total</b> - {{ data.item.bandwidth.total }}
          </template>
        </b-table>
        <template #modal-footer>
          <div class="w-100">
            <b-button-group size="sm">
              <b-button variant="success" @click="exportCSV">Export to CSV</b-button>
              <b-button variant="primary" @click="exportPDF">Export to PDF</b-button>
            </b-button-group>
            <b-button
                variant="info"
                size="sm"
                class="float-right"
                @click="hideStatisticModal"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import consts from "@/consts";
import EventBus from "@/services/EventBus";
import ApiClient from "@/services/ApiClient";
import Auth from '@/services/Auth';
import GroupForm from '@/components/group/GroupForm';
import { generatePdfForStatistic, generateCsvForStatistic } from "@/helpers";

export default {
  name: "groups-list",
  components: { GroupForm },
  props: ["inputCustomerId", "inputGroupId"],
  data() {
    return {
      customerId: null,
      groupId: null,
      groups: [],
      user: Auth.user,
      columns: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "port",
          label: "Port",
          sortable: true
        },
        {
          key: "state",
          label: "State",
          sortable: true
        },
        {
          key: "action",
          class: "action",
          label: "Actions"
        }
      ],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      selectedGroup: {},
      states: {
        active: 'active',
        inactive: 'disabled'
      },
      statistics: [],
      statsForReport: {}
    };
  },
  methods: {
    disableGroup(id) {
      this.$set(this, 'isBusy', true);

      ApiClient
        .deleteGroup(id)
        .then(() => {
          this.$set(this, 'isBusy', false);

          this.refreshList();
        });
    },
    setSelectedGroup(item) {
      this.$set(this, 'selectedGroup', item);

      if (item.state !== this.states.inactive) {
        ApiClient.getGroupSubnet(item.id)
            .then(response => {
              if (!this.$refs.formEdit) return false;
              this.$refs.formEdit.formData.subnet.value = response.data;
              this.selectedGroup['subnet'] = response.data;
            });

        ApiClient.findRoutes(item.id)
            .then(response => {
              if (!this.$refs.formEdit) return false;
              this.$refs.formEdit.routes = response.data;
            });
      }
    },
    handleEditClose() {
      this.$refs.modalEdit.hide();
    },
    handleEditGroupModalShown() {
      this.$refs.formEdit.setFormDataValues(this.selectedGroup);
    },
    handleNewGroupModalShown() {
      ApiClient
        .getRandomPort()
        .then(response => {
          this.$refs.formNew.setFormDataValues(response.data);
        });
    },
    handleNewOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, 'isBusy', true);
      this.handleNewSubmit();
    },
    handlePing() {
      window.open(this.$router.resolve({ path: `/group/${this.selectedGroup.id}/ping?customerId=${this.customerId}`}).href, "_blank");
    },
    handleTraceRoute() {
      window.open(this.$router.resolve({ path: `/group/${this.selectedGroup.id}/traceroute?customerId=${this.customerId}`}).href, "_blank");
    },
    handleRoutingTables() {
      window.open(this.$router.resolve({ path: `/group/rtables?groupId=${this.selectedGroup.id}&customerId=${this.customerId}`}).href, "_blank");
    },
    handleEditApply(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, 'isBusy', true);
      this.handleEditSubmit(true);
    },
    handleEditSave(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, 'isBusy', true);
      this.handleEditSubmit(false);
    },
    getGroupFormData(formData, isEdit) {
      if (isEdit) {
        return {
          name: formData.name.value || '',
          port: parseInt(formData.port.value) || 0,
          subnet: formData.subnet.value || '',
        };
      } else {
        return {
          name: formData.name.value || '',
          port: parseInt(formData.port.value) || 0,
          customerId: this.customerId,
        };
      }
    },
    handleNewSubmit() {
      this.$refs.formNew.clearFormErrors();

      // submit data
      ApiClient
        .createGroup(this.getGroupFormData(this.$refs.formNew.formData, false))
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalAdd.hide();
          });

          this.refreshList();
          this.$set(this, 'isBusy', false);

          this.$bvToast.toast('This may take some time, please refresh the page to check endpoint status', {
            title: 'Group creation started',
            variant: 'info',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$refs.formNew.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    handleEditSubmit(hideModal) {
      this.$refs.formEdit.clearFormErrors();

      // submit data
      ApiClient
        .updateGroup(this.selectedGroup.id, this.getGroupFormData(this.$refs.formEdit.formData, true))
        .then(() => {
          if (hideModal) {
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs.modalEdit.hide();
            });
          } else {
            EventBus.$emit(consts.EVENT_APP_SUCCESS, 'Changes saved');
          }

          this.refreshList();
          this.$set(this, 'isBusy', false);
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$refs.formEdit.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    refreshList() {
      ApiClient
        .findGroups({ customerId: this.customerId })
        .then(response => {
          this.groups = response.data;
          this.totalRows = this.groups.length;

          EventBus.$emit(consts.EVENT_REFRESH_GROUPS, this.groups);
        })
        .then(() => {
          if (this.groupId) {
            for (const group of this.groups) {
              if (group.id === this.groupId) {
                this.setSelectedGroup(group);

                // open edit form
                this.$root.$emit("bv::show::modal", "modal-edit-group");
                break;
              }
            }
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    isGroupActive(group) {
      if (!group) return false;

      return group.state === this.states.active
    },
    toggleGroupState(data) {
      const action = this.isGroupActive(data) ? "suspend" : "restore";
      this.$set(this, 'isBusy', true);

      ApiClient
        .dispatchActionGroup(this.selectedGroup.id, action)
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalToggleState.hide();
          });

          this.refreshList();
          this.$set(this, 'isBusy', false);
        });
    },
    getGroupStatistic(item) {
      this.statistics = [];

      const date = new Date();
      const from = Date.UTC(date.getFullYear(), date.getMonth(), 1);
      const to = Date.UTC(date.getFullYear(), date.getMonth() + 1, 0);

      ApiClient
        .groupIdStatistic(item.id, { from: from, to: to })
        .then(response => {
          this.statistics = [
            Object.assign(
              { period: `${new Date(from).toISOString().split('T')[0]} - ${new Date(to).toISOString().split('T')[0]}` },
              response.data
            )
          ];
          this.statsForReport = Object.assign({ id: item.id, name: item.name, from: from, to: to }, this.statistics[0]);
          this.$root.$emit("bv::show::modal", "modal-group-stats");
        });
    },
    exportCSV() {
      generateCsvForStatistic(this.statsForReport, this.statsForReport.from, this.statsForReport.to, "Group");
    },
    exportPDF() {
      generatePdfForStatistic(this.statsForReport, this.statsForReport.from, this.statsForReport.to, "Group");
    },
    hideStatisticModal() {
      this.$refs.modalStats.hide()
    }
  },
  watch: {
    perPage: function() {
      this.refreshList();
    }
  },
  mounted() {
    this.customerId = this.inputCustomerId;
    this.groupId = this.inputGroupId;

    this.refreshList();

    setInterval(this.refreshList, 15 * 1000);
  }
};
</script>
