<template>
  <div>
    <b-row>
      <b-col class="col-md-12 col-lg-12">
        <b-row v-if="groupsLoaded && groups.length === 0">
          <b-col>
            <div class="bg-warning py-1 px-3 mb-3">No groups available to create endpoint</div>
          </b-col>
        </b-row>
        <b-row  class="pb-3">
          <b-col class="col-md-6 col-lg-6">
            <h4>Endpoints List</h4>
          </b-col>
          <b-col class="col-md-6 col-lg-6 text-right">
            <b-button
              v-if="user.role!='readonly'"
              size="sm"
              variant="primary"
              :disabled="groups.length === 0"
              v-b-modal.modal-add-endpoint
            >Add New Endpoint</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset-md="6" md="6" class="mb-2">
            <b-form-group label-cols-sm="3" class="mb-0">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          id="endpoints-list"
          striped
          hover
          small
          show-empty
          empty-text="No data available in table"
          :items="endpoints"
          :fields="columns"
          :busy="isBusy"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered">
          <template v-slot:cell(connectionStatus)="data">
            <font-awesome-icon icon="circle"
              v-if="data.item.connectionStatus"
              class="mr-1 connection-icon"
              style="color: #82dd55;" />
            <font-awesome-icon icon="circle"
              v-if="!data.item.connectionStatus"
              class="mr-1 connection-icon"
              style="color: #e23636;" />
          </template>
          <template v-slot:cell(action)="data">
            <font-awesome-icon icon="key"
              v-if="user.role!='readonly' && data.item.type === 'router' && data.item.useQuickVPN"
              id="copyTokenButton"
              class="mr-1 action-icon"
              @click="clipboardTokenHandler(data.item)"/>
            <font-awesome-icon icon="copy"
              v-if="user.role!='readonly'"
              id="copyButton"
              class="mr-1 action-icon"
              @click="clipboardHandler(data.item)"/>
            <font-awesome-icon icon="edit"
              v-if="user.role!='readonly'"
              id="editButton"
              @click="setSelectedEndpoint(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-edit-endpoint/>
            <font-awesome-icon icon="trash-alt"
              v-if="user.role!='readonly'"
              id="disableEnableButton"
              @click="setSelectedEndpoint(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-endpoint-delete />
          </template>
          <template v-slot:cell(group)="data">
            <router-link :to="{ path: `/customer/${customerId}/dashboard?groupId=${data.item.group.id}` }" target="_blank">
              {{ `${data.item.group.name} [udp/${data.item.group.port}]` }}
            </router-link>
          </template>
        </b-table>
        <b-row>
          <div class="col-md-6 col-lg-6">
            <label>Show </label>
            <b-form-select v-model="perPage" class="select-xs">
              <option value=10>10</option>
              <option value=25>25</option>
              <option value=50>50</option>
              <option value=100>100</option>
            </b-form-select>
            <span>entries</span>
          </div>
          <div class="col-md-6 col-lg-6">
            <b-pagination
              v-model="currentPage"
              :total-rows="endpoints.length"
              :per-page="perPage"
              aria-controls="endpoints-list"
              align="right"
              v-bind:hide-goto-end-buttons="true"
            ></b-pagination>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-modal
        id="modal-add-endpoint"
        ref="modalAdd"
        title="New Endpoint"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="handleNewOk"
      >
        <endpoint-form ref="formNew" :groups-list="groups" :customer-id="customerId" @submit.stop.prevent="handleSubmit"></endpoint-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        id="modal-edit-endpoint"
        ref="modalEdit"
        title="Edit Endpoint"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @shown="handleEditEndpointModalShown"
        @ok="handleEditOk"
      >
        <endpoint-form ref="formEdit" is-edit-form="true" :groups-list="groups" @submit.stop.prevent="handleSubmit"></endpoint-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        id="modal-endpoint-delete"
        ref="modalDelete"
        title="Delete Endpoint"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="disableEndpoint(selectedEndpoint.id)"
      >
        <p>Endpoint {{ selectedEndpoint ? selectedEndpoint.name : '' }} will be deleted. Do you want to proceed?</p>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import consts from "@/consts";
import EventBus from "@/services/EventBus";
import ApiClient from "@/services/ApiClient";
import Auth from '@/services/Auth';
import EndpointForm from '@/components/endpoint/EndpointForm';

export default {
  name: "endpoints-list",
  components: { EndpointForm },
  props: ["inputEndpointId", "inputCustomerId"],
  data() {
    return {
      endpointId: null,
      customerId: null,
      groupsLoaded: false,
      groups: [],
      endpoints: [],
      user: Auth.user,
      columns: [
        {
          key: "connectionStatus",
          class: "connection-status",
          label: "Status",
          sortable: false,
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          formatter: type => { return type == "pc" ? "PC" : "Router"}
        },
        {
          key: "group",
          label: "Group",
          sortable: true,
        },
        {
          key: "state",
          label: "State",
          sortable: true
        },
        {
          key: "action",
          class: "action",
          label: "Actions"
        }
      ],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      selectedEndpoint: {},
      states: {
        active: 'active',
        inactive: 'disabled'
      }
    };
  },
  methods: {
    disableEndpoint(id) {
      this.$set(this, 'isBusy', true);

      ApiClient
        .deleteEndpoint(id)
        .then(() => {
          this.$set(this, 'isBusy', false);

          this.refreshList();
        });
    },
    clipboardTokenHandler(item) {
      this.$clipboard(item.token);
      this.$bvToast.toast('Quick VPN token copied!', {
        title: 'Info',
        variant: 'info',
        toaster: 'b-toaster-bottom-right',
        solid: true
      });
    },
    clipboardHandler(item){
      this.$clipboard(item.configuration);
      this.$bvToast.toast('Configuration key copied!', {
        title: 'Info',
        variant: 'info',
        toaster: 'b-toaster-bottom-right',
        solid: true
      });
    },
    setSelectedEndpoint(item) {
      this.$set(this, 'selectedEndpoint', item);
    },
    handleEditEndpointModalShown() {
      this.$refs.formEdit.setFormDataValues(this.selectedEndpoint);
    },
    handleNewOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, 'isBusy', true);
      this.handleNewSubmit();
    },
    handleEditOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, 'isBusy', true);
      this.handleEditSubmit();
    },
    getEndpointFormData(formData) {
      return {
        name: formData.name.value || '',
        type: formData.type.value || '',
        groupId: formData.groupId.value || '',
        useQuickVPN: formData.useQuickVPN.value || false
      };
    },
    handleNewSubmit() {
      this.$refs.formNew.clearFormErrors();

      // submit data
      ApiClient
        .createEndpoint(this.getEndpointFormData(this.$refs.formNew.formData))
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalAdd.hide();
          });

          this.refreshList();
          this.$set(this, 'isBusy', false);

          this.$bvToast.toast('This may take some time, please refresh the page to check endpoint status', {
            title: 'Endpoint creation started',
            variant: 'info',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$refs.formNew.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    handleEditSubmit() {
      this.$refs.formEdit.clearFormErrors();

      // submit data
      ApiClient
        .updateEndpoint(this.selectedEndpoint.id, this.getEndpointFormData(this.$refs.formEdit.formData))
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalEdit.hide();
          });

          this.refreshList();
          this.$set(this, 'isBusy', false);
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$refs.formEdit.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    refreshList() {
      ApiClient
        .findEndpoints({ customerId: this.customerId })
        .then(response => {
          this.endpoints = response.data;
          this.totalRows = this.endpoints.length;

          for (const endpoint of this.endpoints) {
            if (endpoint.id === this.endpointId) {
              this.setSelectedEndpoint(endpoint);

              // open edit form
              this.$root.$emit("bv::show::modal", "modal-edit-endpoint");
              break;
            }
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    isEndpointActive(endpoint) {
      if (!endpoint) return false;

      return endpoint.state === this.states.active
    },
  },
  watch: {
    perPage: function() {
      this.refreshList();
    },
  },
  created() {
    this.endpointId = this.inputEndpointId;
    this.customerId = this.inputCustomerId;

    EventBus.$on(consts.EVENT_REFRESH_GROUPS, (groups) => {
      this.groups = [];

      groups.forEach(element => {
        this.groups.push({
          value: element.id,
          text: element.name
        });
      });

      this.groupsLoaded = true;
      this.refreshList();
    });
  },
  mounted() {
    this.endpointId = this.inputEndpointId;
    this.customerId = this.inputCustomerId;
  }
};
</script>

<style>
/* table > tbody > tr > td:first-child {
  width: 20px !important;
} */
</style>
