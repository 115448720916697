export default {
  EVENT_WRONG_AUTH_DATA: 'EVENT_WRONG_AUTH_DATA',
  EVENT_APP_ERROR: 'EVENT_APP_ERROR',
  EVENT_LOGOUT_SUCCESS: 'EVENT_LOGOUT_SUCCESS',
  EVENT_LOGIN_SUCCESS: 'EVENT_LOGIN_SUCCESS',
  EVENT_APP_SUCCESS: 'EVENT_APP_SUCCESS',
  EVENT_APP_INFO: 'EVENT_APP_INFO',
  EVENT_CHANGE_VIEW_SCOPE: 'EVENT_CHANGE_VIEW_SCOPE',
  STATE_ACTION_SUSPEND: 'suspend',
  STATE_ACTION_RESTORE: 'restore',

  EVENT_REFRESH_GROUPS: 'EVENT_REFRESH_GROUPS',

  // in milliseconds
  SESSION_IDLE_TIMEOUT: 1200000,
}
