<template>
  <div>
    <b-row>
      <b-col class="col-12">
        <b-card title="Routing Table">
          <b-card-text>
            <b-row>
              <b-col class="col-12">
                <b-form-group
                  label="Selected Group"
                  label-cols-md="6"
                  label-for="group-input">
                  <b-input-group>
                    <b-form-select
                      id="group-input"
                      v-model="groupId.value"
                      :disabled="isBusy"
                      :options="groupsList"
                      :class="{'border-danger': groupId.error}"
                      size="md">
                    </b-form-select>
                    <b-button :disabled="!groupId.value" @click="handleExternalLink">
                      <font-awesome-icon icon="external-link-alt"/>
                    </b-button>
                  </b-input-group>
                  <div class="text-danger" v-if="groupId.error">{{ groupId.error }}</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  id="rtable-list"
                  striped
                  hover
                  small
                  show-empty
                  empty-text="No data available in table"
                  :busy="isBusy"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :items="results"
                  :fields="fields">
                  <template v-slot:cell(endpoint)="data">
                    <router-link :to="{ path: `/customer/${customerId}/dashboard?endpointId=${data.item.endpoint.id}` }" target="_blank">
                      {{ data.item.endpoint.name }}
                    </router-link>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <div class="col-md-6 col-lg-6">
                <label>Show </label>
                <b-form-select v-model="perPage" class="select-xs">
                  <option value=10>10</option>
                  <option value=25>25</option>
                  <option value=50>50</option>
                  <option value=100>100</option>
                </b-form-select>
                <span>entries</span>
              </div>
              <div class="col-md-6 col-lg-6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="results.length"
                  :per-page="perPage"
                  aria-controls="rtable-list"
                  align="right"
                  v-bind:hide-goto-end-buttons="true"
                ></b-pagination>
              </div>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
 
<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import ApiClient from "@/services/ApiClient";
 
export default {
  name: "rtable-group",
  data() {
    return {
      // seconds
      CHECK_RESPONSE_TIME: 2 * 1000,
      // Max number of retries
      RETRY_NUMBER: 20,
      currentRetries: 0,
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      results: [],
      queryGroupId: this.$route.query.groupId,
      groupId: {
        value: '',
        error: ''
      },
      groupsList: [],
      fields: [
        {
          key: "row",
          label: "Row",
          sortable: true
        },
        {
          key: "network",
          label: "Network",
          sortable: true
        },
        {
          key: "mask",
          label: "Mask",
          sortable: true
        },
        {
          key: "endpoint",
          label: "Endpoint Name",
          sortable: true
        }
      ],
      commandId: null,
    };
  },
  methods: {
    handleExternalLink() {
      window.open(this.$router.resolve({ path: `/customer/${this.customerId}/dashboard?groupId=${this.groupId.value}`}).href, "_blank");
    },
    retrieveGroups() {
      ApiClient
        .findGroups({ customerId: this.customerId })
        .then(response => {
          this.groupsList = [];
          response.data.forEach(element => {
            this.groupsList.push({
              value: element.id,
              text: element.name
            });
          });
        })
        .then(() => {
          if (this.queryGroupId) {
            this.groupId.value = this.queryGroupId;
          }
        });
    },
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'customer', this.customerId);
    },
    getCommandResult(scope) {
      ApiClient.getRoutingTableCommand(scope.commandId)
        .then((response) => {
          if (!["active", "failed"].includes(response.data.state)) {
            // increase counter
            scope.currentRetries += 1;

            if (scope.currentRetries <= scope.RETRY_NUMBER) {
              setTimeout(
                scope.getCommandResult,
                scope.CHECK_RESPONSE_TIME,
                scope
              );

              if (scope.currentRetries % 3 === 0) 
                EventBus.$emit(
                  consts.EVENT_APP_INFO,
                  "The command is processing. Please, wait."
                );
            } else {
              scope.isBusy = false;

              EventBus.$emit(
                consts.EVENT_APP_ERROR,
                "The server does not process this command. Please try later."
              );
            }
          } else {
            // parse response
            scope.parseCommandResult(response.data.raw);

            // enable table and buttons
            scope.isBusy = false;

            if (response.data.state === "active")
              EventBus.$emit(
                consts.EVENT_APP_SUCCESS,
                "The rounting table command executed successfully. Please, check the table of results or text output of this command."
              );
            else
              EventBus.$emit(
                consts.EVENT_APP_ERROR,
                "The command failed. Please, check the raw output of this command."
              );
          }
        })
        .catch((error) => console.error(error));
    },
    parseCommandResult(raw) {
      this.results = [];

      const rows = raw.split("\n").filter(r => r !== '');
      let resultRow = 1;
      let currentEndpoint;

      for (const row of rows) {
        let parsedRow = row.match(/filename: \/etc\/openvpn\/ccd\/(\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)/);

        // Add parsed row to array of results
        if (parsedRow) {
          currentEndpoint = parsedRow[1];

          ApiClient.getEndpoint(parsedRow[1])
            .then((response) => {
              if (response.data) {
                for (const result of this.results) {
                  if (result.endpoint.id === response.data.id) {
                    result.endpoint.name = response.data.name;
                  }
                }
              }
            })
            .catch((error) => console.error(error));
        } else {
          // parse pc routes
          parsedRow = row.match(/push "route (.+) (.+)"/);

          if (parsedRow) {
            this.results.push({ row: resultRow, network: parsedRow[1], mask: parsedRow[2], endpoint: { name: currentEndpoint, id: currentEndpoint } });
            resultRow++;
          } else {
            // parse router route
            parsedRow = row.match(/iroute (.+) (.+)/);

            if (parsedRow) {
              this.results.push({ row: resultRow, network: parsedRow[1], mask: parsedRow[2], endpoint: { name: currentEndpoint, id: currentEndpoint } });
              resultRow++;
            }
          }
        }
      }
    },
    handleGroupSelectChange() {
      ApiClient.executeRountingTableCommand(this.groupId.value, {})
        .then((response) => {
          this.commandId = response.data.id;

          EventBus.$emit(
            consts.EVENT_APP_INFO,
            "You need to wait until the server is processing this command. Please, do not refresh this page."
          );
          this.getCommandResult(this);
        })
        .catch((error) => {
          this.isBusy = false;

          error.response.data.map((errorItem) => {
            this.$set(
              this.formData[errorItem.fieldPath],
              "error",
              errorItem.message
            );
          });
        });
    },
  },
  created() {
    this.customerId = this.$route.query.customerId;

    window.addEventListener('load', this.handleLoadEvent)
  },
  mounted() {
    this.retrieveGroups();
  },
  watch: {
    "groupId.value": function() {
      this.isBusy = true;

      this.handleGroupSelectChange()
    },
  }
};
</script>
