<template>
  <div>
    <b-row>
      <user-list input-title="Admin accounts" role="admin" />
    </b-row>
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import UserList from '@/components/user/UserList';

export default {
  name: "admin-accounts",
  components: { UserList },
  data() {
    return {}
  },
  mounted() {
    window.addEventListener('load', () => {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'admin');
    });
  }
};
</script>
