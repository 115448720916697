<template>
  <div>
    <groups-list class="mb-4" :input-customer-id="customerId" :input-group-id="groupId" />
    <endpoints-list :input-customer-id="customerId" :input-endpoint-id="endpointId" />
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import EndpointsList from "@/components/endpoint/EndpointsList.vue";
import GroupsList from "@/components/group/GroupsList.vue";

export default {
  name: "customer-dashboard",
  components: { EndpointsList, GroupsList },
  data() {
    return {
      customerId: null,
      groupId: null,
      endpointId: null
    }
  },
  methods: {
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'customer', this.customerId);
    }
  },
  created() {
    this.customerId = this.$route.params.id;
    this.groupId = this.$route.query.groupId;
    this.endpointId = this.$route.query.endpointId;

    window.addEventListener('load', this.handleLoadEvent);
  },
  mounted() {
    this.customerId = this.$route.params.id;
    this.groupId = this.$route.query.groupId;
    this.endpointId = this.$route.query.endpointId;
  }
}
</script>
