<template>
  <div>
    <b-row>
      <b-col>
        <p class="text-center" v-html="message" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";

export default {
  name: "endpoints-list",
  data() {
    return {
      token: this.$route.params.token,
      message: ""
    };
  },
  mounted: function () {
    ApiClient
      .confirmQuickVPNRequest(this.token)
      .then((response) => {
        this.message = "The connection confirmed successfully."

        if (response.data.warnings) {
          for (const message of response.data.warnings) {
            this.message += `<br>${message}`;
          }
        }

      })
      .catch((error) => {
        this.message = error.response.data;
      });
  }
};
</script>
