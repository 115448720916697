<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-lg-6">
        <h4>Admin accounting</h4>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <div class="col-md-6 col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            <label>From:</label>
            <b-form-datepicker
              placeholder="From"
              locale="en"
              v-model="filters.from"
              value-as-date
              :max="filterMax"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: 'numeric',
              }"
            >
            </b-form-datepicker>
          </div>
          <div class="col-lg-6">
            <label>To:</label>
            <b-form-datepicker
              placeholder="To"
              locale="en"
              v-model="filters.to"
              value-as-date
              :max="filterMax"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: 'numeric',
              }"
            >
            </b-form-datepicker>
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          small
          show-empty
          empty-text="No data available in table"
          :items="stats"
          sort-by="bandwidth"
          :sort-desc="true"
          :fields="columns"
        >
          <template v-slot:cell(name)="data">
            <router-link
              :to="{ path: `/partner/${data.item.id}/dashboard` }"
              target="_blank"
            >
              {{ data.item.name }}
            </router-link>
          </template>
          <template v-slot:cell(bandwidth)="data">
            <b>RX</b> - {{ data.item.bandwidth.RX }} <b>TX</b> -
            {{ data.item.bandwidth.TX }} <b>Total</b> -
            {{ data.item.bandwidth.total }}
          </template>
          <template v-slot:cell(action)="data">
            <font-awesome-icon
              icon="file-csv"
              @click="exportCSV(data.item)"
              size="lg"
              title="Export to CSV"
              class="mr-1 action-icon"
            />
            <font-awesome-icon
              icon="file-pdf"
              size="lg"
              @click="exportPDF(data.item)"
              title="Export to PDF"
              class="mr-1 action-icon"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import consts from "@/consts";
import EventBus from "@/services/EventBus";
import ApiClient from "@/services/ApiClient";
import { generatePdfForStatistic, generateCsvForStatistic } from "@/helpers";

export default {
  name: "admin-accounting",
  data() {
    return {
      filters: {
        from: new Date(),
        to: new Date(),
      },
      filterMax: new Date(),
      columns: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "bandwidth",
          label: "Bandwidth (Gb)",
          sortable: true,
          formatter: (email) => (email ? email : "N/A"),
        },
        {
          key: "usedEndpoints",
          label: "Used endpoints",
          sortable: true,
        },
        {
          key: "action",
          class: "actions",
          label: "Actions",
        },
      ],
      stats: [],
      partners: [],
    };
  },
  methods: {
    async retrieveStats() {
      for (const index in this.stats) {
        const data = await ApiClient.partnerIdStatistic(this.stats[index].id, {
          from: new Date(this.filters.from).setHours(0, 0, 0, 0),
          to: new Date(this.filters.to).setHours(23, 59, 59, 999),
        }).then((response) => response.data);

        this.stats[index].usedEndpoints = data.usedEndpoints;
        this.stats[index].bandwidth = data.bandwidth;
      }
    },
    retrievePartners() {
      ApiClient.findPartners().then(
        (response) => {
          response.data.map((partner) => {
            this.stats.push({
              id: partner.id,
              name: partner.name,
              bandwidth: {
                RX: "0 B",
                TX: "0 B",
                total: "0 B",
              },
              usedEndpoints: 0,
            });
          });

          this.retrieveStats();
        }
      );
    },
    exportCSV(item) {
      generateCsvForStatistic(item, this.filters.from, this.filters.to, "Partner");
    },
    exportPDF(item) {
      generatePdfForStatistic(item, this.filters.from, this.filters.to, "Partner");
    },
    _isDayBefore(from, to) {
      const dateFrom = new Date(from).setHours(0, 0, 0, 0);
      const dateTo = new Date(to).setHours(0, 0, 0, 0);

      return dateTo - dateFrom < 0;
    },
  },
  watch: {
    "filters.from": function (date) {
      const isAfter = this._isDayBefore(date, this.filters.to);

      if (isAfter) {
        this.filters.to = new Date(date);
      }

      this.retrieveStats();
    },
    "filters.to": function (date) {
      const isBefore = this._isDayBefore(this.filters.from, date);

      if (isBefore) {
        this.filters.from = new Date(date);
      }

      this.retrieveStats();
    },
  },
  mounted() {
    this.retrievePartners();
    window.addEventListener("load", () => {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, "admin");
    });
  },
};
</script>
