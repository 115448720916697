export default {
  methods: {
    setFormDataValues(formDataValues) {
      for (let field in this.formData) {
        this.$set(this.formData[field], 'value', formDataValues[field]);
      }
    },
    setFieldError(field, errorMessage) {
      this.$set(this.formData[field], 'error', errorMessage);
    },
    clearFormErrors() {
      for (let field in this.formData) {
        this.$set(this.formData[field], 'error', null);
      }
    },
  }
};