<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-form-group
      label="Name"
      label-for="name-input"
    >
      <b-form-input
        id="name-input"
        v-model="formData.name.value"
        :class="{'border-danger': formData.name.error}"
      ></b-form-input>
      <div class="text-danger" v-if="formData.name.error">{{ formData.name.error }}</div>
    </b-form-group>
    <b-form-group
      label="Type"
      label-for="type-input"
      v-if="!isEditForm"
    >
      <b-form-select
        id="type-input"
        v-model="formData.type.value"
        :options="types"
        :class="{'border-danger': formData.type.error}"
        size="md"
      ></b-form-select>
      <div class="text-danger" v-if="formData.type.error">{{ formData.type.error }}</div>
    </b-form-group>
    <b-form-group
      label="Group"
      label-for="group-input"
      v-if="!isEditForm"
    >
      <b-input-group>
        <b-form-select
          id="group-input"
          v-model="formData.groupId.value"
          :options="groupsList"
          :class="{'border-danger': formData.groupId.error}"
          size="md"
        ></b-form-select>
        <b-button :disabled="!formData.groupId.value" @click="handleExternalLink">
          <font-awesome-icon icon="external-link-alt"/>
        </b-button>
      </b-input-group>
      <div class="text-danger" v-if="formData.groupId.error">{{ formData.groupId.error }}</div>
    </b-form-group>

    <b-form-group
      label-for="use_quick_vpn-input"
    >
      <b-form-checkbox
        id="use_quick_vpn"
        v-if="formData.type.value === 'router'"
        v-model="formData.useQuickVPN.value"
      >
        Use Quick VPN
      </b-form-checkbox>
      <div class="text-danger" v-if="formData.useQuickVPN.error">{{ formData.useQuickVPN.error }}</div>
    </b-form-group>
    
  </b-form>
</template>
 
<script>
import formsCommonMixin from "../../mixins/formsCommonMixin";
 
export default {
  name: "endpoint-form",
  mixins: [formsCommonMixin],
  props: ['groupsList', 'isEditForm', 'customerId'],
  data() {
    return {
      formData: {
        name: {
          value: '',
          error: null
        },
        type:{
          value: '',
          error: null
        },
        groupId: {
          value: '',
          error: null
        },
        useQuickVPN: {
          value: false,
          error: null
        }
      },
      customerIdForExternalLink: this.customerId,
      groups: this.groupsList,
      types: [ {text: "Router", value: "router"}, {text: "PC", value: "pc"} ]
    }
  },
  methods: {
    clearForm() {
      this.$set(this.formData.name, 'value', '');
      this.$set(this.formData.type, 'value', '');
      this.$set(this.formData.groupId, 'value', null);
      this.$set(this.formData.useQuickVPN, 'value', false);
    },
    handleExternalLink() {
      window.open(this.$router.resolve({ path: `/customer/${this.customerIdForExternalLink}/dashboard?groupId=${this.formData.groupId.value}`}).href, "_blank");
    }
  }
};
</script>