<template>
  <b-row>
    <b-col lg="12" md="12">
      <b-card class="logs-list" header-tag="header">
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Logs</h4>
            <b-button @click="exportLogs">Export logs</b-button>
          </div>
        </template>
        <b-row class="mb-3">
          <div class="col-md-6 col-lg-6">
            <div class="row">
              <div class="col-lg-6">
                <label>From:</label>
                <b-form-datepicker
                  placeholder="From"
                  locale="en"
                  v-model="filters.from"
                  value-as-date
                  :max="filterMax"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: 'numeric',
                  }"
                >
                </b-form-datepicker>
              </div>
              <div class="col-lg-6">
                <label>To:</label>
                <b-form-datepicker
                  placeholder="To"
                  locale="en"
                  v-model="filters.to"
                  value-as-date
                  :max="filterMax"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: 'numeric',
                  }"
                >
                </b-form-datepicker>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="col-lg-6">
              <label>Level:</label>
              <b-form-select
                v-model="filters.level"
                :options="levels"
              ></b-form-select>
            </div>
            <div class="col-lg-6"></div>
          </div>
        </b-row>
        <b-row class="mb-3">
          <div class="col-md-6 col-lg-6">
            <div class="row">
              <div v-if="role === 'admin'" class="col-lg-6">
                <label>Partner:</label>
                <b-form-select
                  v-model="filters.partner"
                  :options="partners"
                ></b-form-select>
              </div>
              <div
                v-if="role === 'admin' || role === 'partner'"
                class="col-lg-6"
              >
                <label>Customer:</label>
                <b-form-select
                  v-model="filters.customer"
                  :options="customers"
                  :disabled="disableCustomerFilter"
                ></b-form-select>
              </div>
            </div>
          </div>
        </b-row>
        <b-table
          :items="logs"
          :fields="fields"
          :tbody-tr-class="rowClass"
          small
          responsive
          show-empty
          empty-text="No data available in table"
          @sort-changed="sortChanged"
        >
        </b-table>
        <b-row>
          <div class="col-md-6 col-lg-6">
            <label>Show </label>
            <b-form-select v-model="logsLimit" class="select-xs">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </b-form-select>
            <span>entries</span>
          </div>
          <p class="text-right col-md-6">
            <b-button-group size="sm">
              <b-button
                v-if="this.logs.length >= this.logsLimit"
                variant="primary"
                @click="handleLoadPrevLogsClick()"
                >← Previous</b-button
              >
              <b-button
                v-if="this.logsOffset > 0"
                variant="success"
                @click="handleLoadNewLogsClick()"
                >Next →</b-button
              >
            </b-button-group>
          </p>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Auth from "@/services/Auth";
import ApiClient from "@/services/ApiClient";
import { generateCsvForLogs } from "@/helpers";

export default {
  name: "sys-log",
  props: {
    role: {
      required: true,
      validator: function (value) {
        const roles = ["admin", "partner", "customer"];

        return roles.indexOf(value) !== -1;
      },
    },
    partnerId: {},
    customerId: {},
  },
  data() {
    return {
      user: Auth.user,
      logs: [],
      logsOffset: 0,
      logsLimit: 10,
      filters: {
        from: new Date(new Date().setHours(0, 0, 0, 0)),
        to: new Date(new Date().setHours(23, 59, 59, 999)),
        level: null,
        customer: null,
        partner: null,
      },
      filterMax: new Date(),
      customers: [{ text: "", value: null }],
      partners: [{ text: "", value: null }],
      levels: [
        { text: "all", value: null },
        { text: "info", value: "INFO" },
        { text: "warning", value: "WARNING" },
        { text: "error", value: "ERROR" },
      ],
      fields: [
        {
          key: "id",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "level",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "user",
          formatter: (value) => value ? value.username : "",
        },
        {
          key: "remoteAddr",
          label: "Host",
          sortable: true,
        },
        {
          key: "message",
        },
        {
          key: "responseTime",
          label: "Response Time",
          formatter: (value) => value ? `${value} ms` : "",
        },
        {
          key: "contentLength",
          label: "Content Length",
        },
        {
          key: "createdAt",
          sortable: true,
        },
      ],
      disableCustomerFilter: false,
      sortBy: "createdAt",
      sort: "desc",
    };
  },
  methods: {
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.sort = e.sortDesc ? "desc" : "asc";

      this.retrieveLogs();
    },
    retrieveCustomers() {
      this.customers = [{ text: "", value: null }];

      if (this.role === "admin") {
        ApiClient.findCustomers().then((response) => {
          response.data.map((customer) => {
            if (this.filters.partner === customer.partner.id)
              this.customers.push({ text: customer.name, value: customer.id });
          });

          this.filters.customer = this.customers[0].value;
        });
      } else if (this.role === "partner") {
        // TODO: app partnerId
        ApiClient.findCustomers().then((response) => {
          response.data.map((customer) => {
            if (this.partnerId === customer.partner.id)
              this.customers.push({ text: customer.name, value: customer.id });
          });

          this.filters.customer = this.customers[0].value;
        });
      }
    },
    refreshFilters() {
      this.partners = [{ text: "", value: null }];
      this.customers = [{ text: "", value: null }];

      if (this.role === "admin") {
        this.disableCustomerFilter = true;

        ApiClient.findPartners().then((response) => {
          response.data.map((partner) => {
            this.partners.push({ text: partner.name, value: partner.id });
          });

          this.filters.partner = this.partners[0].value;
        });
      } else {
        this.retrieveCustomers();
      }
    },
    getFilters() {
      const params = {
        offset: this.logsOffset,
        limit: this.logsLimit,
        sort: this.sort,
        sortBy: this.sortBy,
        partnerId: this.filters.partner || this.partnerId,
        customerId: this.filters.customer || this.customerId,
        level: this.filters.level,
        from: this.filters.from,
        to: this.filters.to,
      };

      Object.keys(params).forEach(
        (k) => params[k] === null && delete params[k]
      );

      return params;
    },
    retrieveLogs() {
      ApiClient.retrieveLogs(this.getFilters()).then((response) => {
        response.data.map((log) => {
          log.level = log.level.toLowerCase();
        });

        this.logs = response.data;
      });
    },
    handleLoadNewLogsClick() {
      this.logsOffset = Math.max(this.logsOffset - this.logsLimit, 0);
    },
    handleLoadPrevLogsClick() {
      if (this.logs.length < this.logsLimit) {
        return;
      }

      this.logsOffset = this.logsOffset + this.logsLimit;
    },
    _isDayBefore(from, to) {
      const dateFrom = new Date(from).setHours(0, 0, 0, 0);
      const dateTo = new Date(to).setHours(0, 0, 0, 0);

      return dateTo - dateFrom < 0;
    },
    exportLogs() {
      const params = this.getFilters();

      // change export params
      params.limit = 1000;
      params.offset = 0;

      ApiClient.retrieveLogs(params).then((response) => {
        response.data.map((log) => {
          log.level = log.level.toLowerCase();
        });

        generateCsvForLogs(response.data, params);
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return null;

      return item.level;
    },
  },
  watch: {
    logsOffset: function () {
      this.retrieveLogs();
    },
    logsLimit: function () {
      this.logsLimit = +this.logsLimit;
      this.retrieveLogs();
    },
    "filters.from": function (date) {
      const isAfter = this._isDayBefore(date, this.filters.to);

      if (isAfter) {
        this.filters.to = new Date(date);
      }

      this.retrieveLogs();
    },
    "filters.to": function (date) {
      const isBefore = this._isDayBefore(this.filters.from, date);

      if (isBefore) {
        this.filters.from = new Date(date);
      }

      this.retrieveLogs();
    },
    "filters.level": function () {
      this.retrieveLogs();
    },
    "filters.customer": function () {
      this.retrieveLogs();
    },
    "filters.partner": function (value) {
      if (value) this.disableCustomerFilter = false;
      else this.disableCustomerFilter = true;

      this.retrieveCustomers();
      this.retrieveLogs();
    },
  },
  mounted() {
    this.user = Auth.user;

    this.refreshFilters();
    this.retrieveLogs();
  },
};
</script>

<style>
tr.info {
  background-color: rgba(var(--info-rgb), 0.2);
}
tr.warning {
  background-color: rgba(var(--warning-rgb), 0.2);
}
tr.error {
  background-color: rgba(var(--danger-rgb), 0.2);
}
</style>
