<template>
  <div>
    <sys-log role="admin"/>
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import SysLog from '@/components/SysLog';

export default {
  name: "admin-syslog",
  components: { SysLog },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener('load', () => {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'admin');
    });
  }
};
</script>
