<template>
  <div>
    <b-row>
      <b-col xl="12" lg="12" md="12" class="mb-1">
        <b-card title="Host / Address">
          <b-card-text>
            <!-- IP Address and Resolve Hostname -->
            <b-row>
              <b-col class="col-lg-12 col-md-12 col-sm-12 col-12">
                <b-form-group
                  label="IP Address:"
                  label-cols-md="4"
                  label-for="ip-address-input"
                  label-align="left"
                >
                  <b-form-input
                    id="ip-address-input"
                    v-model="formData.host.value"
                    :disabled="isBusy"
                    :class="{ 'border-danger': formData.host.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.host.error">
                    {{ formData.host.error }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col xl="12" lg="12" md="12" class="mb-1">
        <b-card title="Options">
          <b-card-text class="mt-2">
            <!-- Timeout and Buffer size -->
            <b-row>
              <b-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <b-form-group
                  label="Timeout (in sec):"
                  label-cols-md="7"
                  label-col="6"
                  label-for="timeout-input"
                  label-align="left"
                >
                  <b-form-input
                    id="timeout-input"
                    type="number"
                    v-model="formData.timeout.value"
                    :number="true"
                    :disabled="isBusy"
                    :class="{ 'border-danger': formData.timeout.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.timeout.error">
                    {{ formData.timeout.error }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <b-form-group
                  label="Buffer Size:"
                  label-cols-md="7"
                  label-for="buffer-size-input"
                  label-align="left"
                >
                  <b-form-input
                    id="buffer-size-input"
                    type="number"
                    v-model="formData.bufferSize.value"
                    :number="true"
                    :disabled="isBusy"
                    :class="{ 'border-danger': formData.bufferSize.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.bufferSize.error">
                    {{ formData.bufferSize.error }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Echo requests and Time To Live -->
            <b-row>
              <b-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <b-form-group
                  label="Echo requests:"
                  label-cols-md="7"
                  label-for="echo-requests-input"
                  label-align="left"
                >
                  <b-form-input
                    id="buffer-size-input"
                    type="number"
                    v-model="formData.requests.value"
                    :number="true"
                    :disabled="isBusy"
                    :class="{ 'border-danger': formData.requests.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.requests.error">
                    {{ formData.requests.error }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <b-form-group
                  label="Time To Live:"
                  label-cols-md="7"
                  label-for="ttl-input"
                  label-align="left"
                >
                  <b-form-input
                    id="ttl-input"
                    type="number"
                    v-model="formData.ttl.value"
                    :number="true"
                    :disabled="isBusy"
                    :class="{ 'border-danger': formData.ttl.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.ttl.error">
                    {{ formData.ttl.error }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <b-card-text>
            <b-row class="mb-2">
              <b-col class="col-md-8 col-8">
                <b>{{ commandStatistics.transmitted }}</b> packets transmitted, <b>{{ commandStatistics.received }}</b> packets received, <b>{{ commandStatistics.loss }}%</b> packet loss
              </b-col>
              <b-col class="col-md-4 col-4 text-right">
                <b-button
                  class="mr-1"
                  size="md"
                  variant="primary"
                  :disabled="commandRawOutput === ''"
                  v-b-modal.modal-show-raw-output
                >
                  Show Text Output
                </b-button>
                <b-button
                  size="md"
                  variant="secondary"
                  :disabled="isBusy"
                  @click="handlePingButton"
                >
                  Ping
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  id="ping-result"
                  striped
                  hover
                  small
                  show-empty
                  empty-text="No data available in table"
                  :busy="isBusy"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :items="results"
                  :fields="fields"
                >
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <div class="col-md-6 col-lg-6">
                <label>Show </label>
                <b-form-select v-model="perPage" class="select-xs">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                </b-form-select>
                <span>entries</span>
              </div>
              <div class="col-md-6 col-lg-6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="results.length"
                  :per-page="perPage"
                  aria-controls="ping-result"
                  align="right"
                  v-bind:hide-goto-end-buttons="true"
                ></b-pagination>
              </div>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
        id="modal-show-raw-output"
        title="Ping Command Text Output"
        size="lg"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        ok-only
      >
        <p class="pre-formatted text-left">{{ commandRawOutput }}</p>
    </b-modal>
  </div>
</template>
 
<script>
import consts from "@/consts";
import EventBus from "@/services/EventBus";
import ApiClient from "@/services/ApiClient";

export default {
  name: "ping-group",
  data() {
    return {
      // seconds
      CHECK_RESPONSE_TIME: 2 * 1000,
      // Max number of retries
      RETRY_NUMBER: 20,
      currentRetries: 0,
      groupId: this.$route.params.id,
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      results: [],
      commandRawOutput: '', 
      commandStatistics: {
        transmitted: 0,
        received: 0,
        loss: 0,
      },
      fields: [
        {
          key: "seq",
          label: "Seq. Number",
          sortable: true,
        },
        {
          key: "ipAddress",
          label: "IP Address",
          sortable: true,
        },
        {
          key: "bytes",
          label: "Bytes",
          sortable: true,
          formatter: (value) => `${value} bytes`,
        },
        {
          key: "time",
          label: "Time",
          sortable: true,
          formatter: (value) => `${value} ms`,
        },
        {
          key: "ttl",
          label: "TTL",
          sortable: true,
        },
      ],
      formData: {
        host: {
          value: "",
          error: "",
        },
        timeout: {
          value: 15,
          error: "",
        },
        bufferSize: {
          value: 56,
          error: "",
        },
        requests: {
          value: 10,
          error: "",
        },
        ttl: {
          value: 128,
          error: "",
        },
      },
      commandId: null,
    };
  },
  methods: {
    handleLoadEvent() {
      EventBus.$emit(
        consts.EVENT_CHANGE_VIEW_SCOPE,
        "customer",
        this.customerId
      );
    },
    getFormData(formData) {
      return {
        host: formData.host.value,
        timeout: formData.timeout.value,
        buffSize: formData.bufferSize.value,
        requests: formData.requests.value,
        ttl: formData.ttl.value,
      };
    },
    getCommandResult(scope) {
      ApiClient.getPingCommand(scope.commandId)
        .then((response) => {
          if (!["active", "failed"].includes(response.data.state)) {
            // increase counter
            scope.currentRetries += 1;

            if (scope.currentRetries <= scope.RETRY_NUMBER) {
              setTimeout(
                scope.getCommandResult,
                scope.CHECK_RESPONSE_TIME,
                scope
              );

              if (scope.currentRetries % 3 === 0) 
                EventBus.$emit(
                  consts.EVENT_APP_INFO,
                  "The command is processing. Please, wait."
                );
            } else {
              scope.isBusy = false;

              EventBus.$emit(
                consts.EVENT_APP_ERROR,
                "The server does not process this command. Please try later."
              );
            }
          } else {
            // parse results
            scope.parseCommandResult(response.data.raw);

            // enable table and buttons
            scope.isBusy = false;

            if (response.data.state === "active")
              EventBus.$emit(
                consts.EVENT_APP_SUCCESS,
                "The ping command executed successfully. Please, check the table of results or text output of this command."
              );
            else
              EventBus.$emit(
                consts.EVENT_APP_ERROR,
                "The command failed. Please, check the raw output of this command."
              );
          }
        })
        .catch((error) => console.error(error));
    },
    parseCommandResult(raw) {
      this.commandRawOutput = raw;
      this.results = [];

      // Parse ping command statistics
      if (raw.includes("statistics")) {
        const parsedStatistics = raw.match(/(\d+) packets transmitted, (\d+) packets received, (\d+)% packet loss/);

        if (parsedStatistics) {
          this.commandStatistics.transmitted = parsedStatistics[1] || 0;
          this.commandStatistics.received = parsedStatistics[2] || 0;
          this.commandStatistics.loss = parsedStatistics[3] || 0;
        }
      }

      const rows = raw.split("\n").filter(r => r !== '');

      for (const row of rows) {
        const parsedRow = row.match(/(\d+) bytes from (.+): seq=(\d+) ttl=(\d+) time=(\d.+) ms/);

        // Add parsed row to array of results
        if (parsedRow) {
          this.results.push({ bytes: parsedRow[1], ipAddress: parsedRow[2], seq: parsedRow[3], ttl: parsedRow[4], time: parsedRow[5] });
        }
      }
    },
    setDefaultValues() {
      this.results = [];
      this.isBusy = true;
      this.currentRetries = 0;
      this.commandRawOutput = '';
      this.commandStatistics = {
        transmitted: 0,
        received: 0,
        loss: 0,
      };
    },
    handlePingButton() {
      // set default values
      this.setDefaultValues();

      // clear errors
      for (let field in this.formData)
        this.$set(this.formData[field], "error", null);

      ApiClient.executePingCommand(this.groupId, this.getFormData(this.formData))
        .then((response) => {
          this.commandId = response.data.id;

          EventBus.$emit(
            consts.EVENT_APP_INFO,
            "You need to wait until the server is processing this command. Please, do not refresh this page."
          );
          this.getCommandResult(this);
        })
        .catch((error) => {
          this.isBusy = false;

          error.response.data.map((errorItem) => {
            this.$set(
              this.formData[errorItem.fieldPath],
              "error",
              errorItem.message
            );
          });
        });
    },
  },
  created() {
    this.customerId = this.$route.query.customerId;

    window.addEventListener("load", this.handleLoadEvent);
  }
};
</script>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
