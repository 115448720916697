<template>
  <div>
    <b-row>
      <b-col xl="12" lg="12" md="12" class="mb-1">
        <b-card title="Host / Address">
          <b-card-text>
            <!-- IP Address -->
            <b-row>
              <b-col class="col-lg-12 col-md-12 col-sm-12 col-12">
                <b-form-group
                  label="IP Address:"
                  label-cols-md="4"
                  label-for="ip-address-input"
                  label-align="left"
                >
                  <b-form-input
                    id="ip-address-input"
                    v-model="formData.host.value"
                    :disabled="isBusy"
                    :class="{ 'border-danger': formData.host.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.host.error">
                    {{ formData.host.error }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col xl="12" lg="12" md="12" class="mb-1">
        <b-card title="Options">
          <b-card-text>
            <!-- Hops and Timeout -->
            <b-row>
              <b-col class="col-lg-6 col-md-12 col-sm-12">
                <b-form-group
                  label="Maximum Hops:"
                  label-cols-md="6"
                  label-for="hops-input"
                  label-align="left"
                >
                  <b-form-input
                    id="hops-input"
                    type="number"
                    v-model="formData.hops.value"
                    :disabled="isBusy"
                    :number="true"
                    :class="{ 'border-danger': formData.hops.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.hops.error">
                    {{ formData.hops.error }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="col-lg-6 col-md-12 col-sm-12">
                <b-form-group
                  label="Response Timeout (seconds):"
                  label-cols-md="6"
                  label-for="timeout-input"
                  label-align="left"
                >
                  <b-form-input
                    id="timeout-input"
                    type="number"
                    v-model="formData.requestTimeout.value"
                    :disabled="isBusy"
                    :number="true"
                    :class="{ 'border-danger': formData.requestTimeout.error }"
                  >
                  </b-form-input>
                  <div class="text-danger" v-if="formData.requestTimeout.error">
                    {{ formData.requestTimeout.error }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <b-card-text>
            <b-row class="mb-2">
              <b-col class="offset-8 col-4 text-right">
                <b-button
                  class="mr-1"
                  size="md"
                  variant="primary"
                  :disabled="commandRawOutput === ''"
                  v-b-modal.modal-show-raw-output
                >
                  Show Text Output
                </b-button>
                <b-button
                  size="md"
                  variant="secondary"
                  :disabled="isBusy"
                  @click="handleTraceRouteButton"
                >
                  Trace Route
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  id="trace-route-result"
                  striped
                  hover
                  small
                  show-empty
                  empty-text="No data available in table"
                  :busy="isBusy"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :items="results"
                  :fields="fields"
                >
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <div class="col-md-6 col-lg-6">
                <label>Show </label>
                <b-form-select v-model="perPage" class="select-xs">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </b-form-select>
                <span>entries</span>
              </div>
              <div class="col-md-6 col-lg-6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="results.length"
                  :per-page="perPage"
                  aria-controls="trace-route-result"
                  align="right"
                  v-bind:hide-goto-end-buttons="true"
                ></b-pagination>
              </div>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-show-raw-output"
      title="Traceroute Command Text Output"
      size="lg"
      v-bind:no-close-on-backdrop="true"
      v-bind:no-close-on-esc="true"
      ok-only
    >
      <p class="pre-formatted text-left">{{ commandRawOutput }}</p>
    </b-modal>
  </div>
</template>
 
<script>
import consts from "@/consts";
import EventBus from "@/services/EventBus";
import ApiClient from "@/services/ApiClient";

export default {
  name: "trace-route-group",
  data() {
    return {
      // 2 seconds
      CHECK_RESPONSE_TIME: 2 * 1000,
      // Max number of retries
      RETRY_NUMBER: 1000,
      currentRetries: 0,
      groupId: this.$route.params.id,
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      results: [],
      commandRawOutput: "",
      fields: [
        {
          key: "hop",
          label: "Hop",
          sortable: true,
        },
        {
          key: "ipAddress",
          label: "IP Address",
          sortable: true,
        },
        {
          key: "host",
          label: "Host Name",
          sortable: true,
        },
        {
          key: "time1",
          label: "Time P1",
          formatter: this.timeFormatter,
        },
        {
          key: "time2",
          label: "Time P2",
          formatter: this.timeFormatter,
        },
        {
          key: "time3",
          label: "Time P3",
          formatter: this.timeFormatter,
        },
      ],
      formData: {
        host: {
          value: "",
          error: "",
        },
        hops: {
          value: 30,
          error: "",
        },
        requestTimeout: {
          value: 3,
          error: "",
        },
      },
      commandId: null,
    };
  },
  methods: {
    timeFormatter(value) {
      return Number.parseFloat(value) ? `${value} ms` : value;
    },
    handleLoadEvent() {
      EventBus.$emit(
        consts.EVENT_CHANGE_VIEW_SCOPE,
        "customer",
        this.customerId
      );
    },
    getFormData(formData) {
      return {
        requestTimeout: formData.requestTimeout.value,
        host: formData.host.value,
        hops: formData.hops.value,
      };
    },
    setDafaultValues() {
      this.results = [];
      this.isBusy = true;
      this.currentRetries = 0;
      this.commandRawOutput = "";
    },
    getCommandResult(scope) {
      ApiClient.getTraceRouteCommand(scope.commandId)
        .then((response) => {
          console.log(response.data);

          if (!["active", "failed"].includes(response.data.state)) {
            // increase counter
            scope.currentRetries += 1;

            if (scope.currentRetries <= scope.RETRY_NUMBER) {
              setTimeout(
                scope.getCommandResult,
                scope.CHECK_RESPONSE_TIME,
                scope
              );

              if (scope.currentRetries % 3 === 0)
                EventBus.$emit(
                  consts.EVENT_APP_INFO,
                  "The command is processing. Please, wait."
                );
            } else {
              scope.isBusy = false;

              EventBus.$emit(
                consts.EVENT_APP_ERROR,
                "The server does not process this command. Please try later."
              );
            }
          } else {
            // parse response
            scope.parseCommandResult(response.data.raw);

            // enable table and buttons
            scope.isBusy = false;

            if (response.data.state === "active")
              EventBus.$emit(
                consts.EVENT_APP_SUCCESS,
                "The traceroute command executed successfully. Please, check the table of results or text output of this command."
              );
            else
              EventBus.$emit(
                consts.EVENT_APP_ERROR,
                "The command failed. Please, check the raw output of this command."
              );
          }
        })
        .catch((error) => console.error(error));
    },
    parseCommandResult(raw) {
      this.commandRawOutput = raw;
      this.results = [];

      const rows = raw.split("\n").filter(r => r !== '');

      for (const row of rows) {
        var parsedRow = row.match(/(\d+)\s\s(.+)\s\((.+)\)\s\s(\d+.\d+)\sms\s\s(\d+.\d+)\sms\s\s(\d+.\d+)\sms/);

        // Add parsed row to array of results
        if (parsedRow) {
          this.results.push({ hop: parsedRow[1], host: parsedRow[2], ipAddress: parsedRow[3], time1: parsedRow[4], time2: parsedRow[5], time3: parsedRow[6] });
        } else {
          parsedRow = row.match(/(\d+)\s\s\*\s\s\*\s\s\*/);

          if (parsedRow) this.results.push({ hop: parsedRow[1], host: '*', ipAddress: '*', time1: '*', time2: '*', time3: '*' });
        }
      }
    },
    handleTraceRouteButton() {
      // set default values
      this.setDafaultValues();

      // clear errors
      for (const field in this.formData)
        this.$set(this.formData[field], "error", null);

      // send request to server
      ApiClient.executeTraceRouteCommand(this.groupId, this.getFormData(this.formData))
        .then((response) => {
          this.commandId = response.data.id;

          EventBus.$emit(
            consts.EVENT_APP_INFO,
            "You need to wait until the server is processing this command. Please, do not refresh this page."
          );
          this.getCommandResult(this);
        })
        .catch((error) => {
          this.isBusy = false;

          error.response.data.map((errorItem) => {
            this.$set(
              this.formData[errorItem.fieldPath],
              "error",
              errorItem.message
            );
          });
        });
    },
  },
  created() {
    this.customerId = this.$route.query.customerId;

    window.addEventListener("load", this.handleLoadEvent);
  },
};
</script>
