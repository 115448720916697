<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" class="navbar-custom">
      <b-navbar-brand href="#">
        <img src="/favicon.ico" height="22px" class="mb-1"/>
        <span> Antaira's Cloud VPN</span>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav v-if="user">
        <b-navbar-nav v-if="view_scope === 'admin'">
          <b-nav-item href="#/admin/dashboard">Home</b-nav-item>
          <b-nav-item-dropdown text="Configuration" right>
            <b-dropdown-item href="#/admin/configuration/accounts">Admin Accounts</b-dropdown-item>
            <b-dropdown-item href="#/admin/configuration/smtp">System Settings</b-dropdown-item>
            <b-dropdown-item href="#/admin/configuration/accounting">Accounting</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Tools" right>
            <b-dropdown-item href="#/admin/tools/syslog">Syslog</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav v-else-if="view_scope === 'partner'">
          <b-nav-item v-bind:href="brand_href">Home</b-nav-item>
          <b-nav-item-dropdown text="Configuration" right>
            <b-dropdown-item v-bind:href="configuration_accounts_href">Partner Accounts</b-dropdown-item>
            <b-dropdown-item :href="accounting_href">Accounting</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Tools" right>
            <b-dropdown-item v-bind:href="tools_syslog_href">Syslog</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav v-else-if="view_scope === 'customer'">
          <b-nav-item v-bind:href="brand_href">Home</b-nav-item>
          <b-nav-item-dropdown text="Configuration" right>
            <b-dropdown-item v-bind:href="configuration_accounts_href">Customer Accounts</b-dropdown-item>
            <b-dropdown-item :href="accounting_href">Accounting</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Tools" right>
            <b-dropdown-item v-bind:href="tools_syslog_href">Syslog</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template slot="button-content">
              <em>{{ user.username }}</em>
            </template>
            <b-dropdown-item href="#/profile">Profile</b-dropdown-item>
            <b-dropdown-item href="#" @click.stop.prevent="handleSignoutClick">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="container content">
      <router-view />
    </div>

    <footer>
      <div class="container footer">
        <b-row>
          <b-col class="text-center">
            &#169; {{ getCurrentYear() }} Copyright - <a href="https://www.antaira.com/">Antaira</a>
          </b-col>
          <b-col class="text-center">
            Application Version: {{ version }}
          </b-col>
        </b-row>
      </div>
    </footer>

    <b-modal
      id="modal-search"
      ref="modal"
      title="Search Results"
      size="lg"
      scrollable
      centered
      hide-header-close
      no-close-on-backdrop
    >
      <p class="my-4" v-for="i in 20" :key="i">
        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
        in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
      </p>
      <template slot="modal-footer" slot-scope="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="ok()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>

import ApiClient from "@/services/ApiClient";
import EventBus from '@/services/EventBus';
import Auth from '@/services/Auth';
import consts from '@/consts';

export default {
  name: "app",
  data() {
    return {
      searchString: "",
      brand_href: "#",
      configuration_accounts_href: "#",
      accounting_href: "#",
      tools_syslog_href: "#",
      view_scope: "",
      user: null,
      version: '0.0'
    };
  },
  mounted() {
    EventBus.$on(consts.EVENT_APP_ERROR, errorMsg => {
      this.$bvToast.toast(errorMsg, {
        title: 'Error happened',
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
        solid: true
      });
    });

    EventBus.$on(consts.EVENT_APP_SUCCESS, message => {
      this.$bvToast.toast(message, {
        title: 'Success',
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        solid: true
      });
    });

    EventBus.$on(consts.EVENT_APP_INFO, message => {
      this.$bvToast.toast(message, {
        title: 'Info',
        variant: 'info',
        toaster: 'b-toaster-bottom-right',
        solid: true
      });
    });

    EventBus.$on(consts.EVENT_LOGIN_SUCCESS, user => {
      this.$set(this, 'user', user);
    });

    EventBus.$on(consts.EVENT_LOGOUT_SUCCESS, () => {
      this.$set(this, 'user', null);
    });

    EventBus.$on(consts.EVENT_CHANGE_VIEW_SCOPE, (view_scope, id) => {
      this.$set(this, 'view_scope', view_scope);
      this.$set(this, 'brand_href', `#/${view_scope}${id ? `/${id}` : ''}/dashboard`);
      this.$set(this, 'tools_syslog_href', `#/${view_scope}${id ? `/${id}` : ''}/tools/syslog`)
      this.$set(this, 'configuration_accounts_href', `#/${view_scope}${id ? `/${id}` : ''}/configuration/accounts`)
      this.$set(this, 'accounting_href', `#/${view_scope}${id ? `/${id}` : ''}/configuration/accounting`)
    });

    this.$set(this, 'user', Auth.user);
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    checkFormValidity() {
      const valid = this.$refs.searchForm.checkValidity();
      this.nameState = valid ? "valid" : "invalid";
      return valid;
    },
    handleSearchSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // submit data
      ApiClient
        .performSearch(this.searchString)
        .then(() => {
          // Show the modal manually
          this.$bvModal.show("modal-search");
        })
    },
    handleSignoutClick() {
      Auth.logoutUser();
    },
    getVersion() {
      ApiClient
        .getVersion()
        .then(response => {
          this.version = response.data.version;
        });
    }
  },
  created() {
    this.getVersion();
  },
  watch: {
    'user': function() {
      if (this.user) {
        if (this.user.accountType === 'is_global_admin_account_type') {
          this.view_scope = "admin";
        } else if (this.user.accountType === 'is_partner_account_type') {
          this.brand_href = `#/partner/${this.user.company.customer.partnerId}/dashboard`;
          this.configuration_accounts_href = `#/partner/${this.user.company.customer.partnerId}/configuration/accounts`;
          this.tools_syslog_href = `#/partner/${this.user.company.customer.partnerId}/tools/syslog`;
          this.accounting_href = `#/partner/${this.user.company.customer.partnerId}/configuration/accounting`;
          this.view_scope = "partner";
        } else if (this.user.accountType === 'is_customer_account_type') {
          this.brand_href = `#/customer/${this.user.company.customerId}/dashboard`;
          this.configuration_accounts_href = `#/customer/${this.user.company.customerId}/configuration/accounts`;
          this.tools_syslog_href = `#/customer/${this.user.company.customerId}/tools/syslog`;
          this.accounting_href = `#/customer/${this.user.company.customerId}/configuration/accounting`;
          this.view_scope = "customer";
        }
      } else {
        this.brand_href = "#";
        this.configuration_accounts_href = "#";
        this.tools_syslog_href = "#";
        this.view_scope = "";
      }
    }
  }
};
</script>
