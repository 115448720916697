<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <!-- Username -->
    <b-form-group label="Username" label-for="username-input">
      <b-form-input
        id="username-input"
        v-model="formData.username.value"
        :class="{ 'border-danger': formData.username.error }"
      ></b-form-input>
      <div class="text-danger" v-if="formData.username.error">
        {{ formData.username.error }}
      </div>
    </b-form-group>

    <!-- Email -->
    <b-form-group label="Email" label-for="email-input">
      <b-form-input
        id="email-input"
        v-model="formData.email.value"
        :class="{ 'border-danger': formData.email.error }"
      ></b-form-input>
      <div class="text-danger" v-if="formData.email.error">
        {{ formData.email.error }}
      </div>
    </b-form-group>

    <!-- Description -->
    <b-form-group label="Description" label-for="description-input">
      <b-form-input
        id="description-input"
        v-model="formData.description.value"
        :class="{ 'border-danger': formData.description.error }"
      ></b-form-input>
      <div class="text-danger" v-if="formData.description.error">
        {{ formData.description.error }}
      </div>
    </b-form-group>

    <!-- Role -->
    <b-form-group label="Role" label-for="role-input" v-if="role !== 'admin'">
      <b-form-select
        id="role-input"
        v-model="formData.role.value"
        :options="roles"
        :class="{ 'border-danger': formData.role.error }"
        size="md"
      ></b-form-select>
      <div class="text-danger" v-if="formData.role.error">
        {{ formData.role.error }}
      </div>
    </b-form-group>

    <div v-if="!isEditForm">
      <!-- Account Type -->
      <b-form-group label="Account Type" label-for="account-type-input" v-if="role === 'admin'">
        <b-form-select
          id="account-type-input"
          v-model="formData.accountType.value"
          :options="accountTypes"
          :class="{ 'border-danger': formData.accountType.error }"
          size="md"
        ></b-form-select>
        <div class="text-danger" v-if="formData.accountType.error">
          {{ formData.accountType.error }}
        </div>
      </b-form-group>

      <!-- Partner -->
      <b-form-group label="Partner" label-for="partner-id-input" v-if="role === 'admin'">
        <b-form-select
          id="partner-id-input"
          v-model="formData.partnerId.value"
          :options="partners"
          :disabled="!(formData.accountType.value === 'is_customer_account_type' || formData.accountType.value === 'is_partner_account_type')"
          :class="{ 'border-danger': formData.partnerId.error }"
          size="md"
        ></b-form-select>
        <div class="text-danger" v-if="formData.partnerId.error">
          {{ formData.partnerId.error }}
        </div>
      </b-form-group>

      <!-- Customer -->
      <b-form-group label="Customer" label-for="customer-id-input" v-if="role === 'admin'">
        <b-form-select
          id="customer-id-input"
          v-model="formData.customerId.value"
          :disabled="!(formData.accountType.value === 'is_customer_account_type' && formData.partnerId.value !== '')"
          :options="customersSelectList"
          :class="{ 'border-danger': formData.customerId.error }"
          size="md"
        ></b-form-select>
        <div class="text-danger" v-if="formData.customerId.error">
          {{ formData.customerId.error }}
        </div>
      </b-form-group>
    </div>
  </b-form>
</template>
 
<script>
import formsCommonMixin from "../../mixins/formsCommonMixin";

export default {
  name: "user-form",
  mixins: [formsCommonMixin],
  props: {
    partnersList: {},
    customersList: {},
    rolesList: {},
    accountTypeList: {},
    role: {
      required: true,
      validator: function (value) {
        const roles = ["admin", "partner", "customer"];

        return roles.indexOf(value) !== -1;
      },
    },
    isEditForm: {}
  },
  data() {
    return {
      formData: {
        username: {
          value: "",
          error: null,
        },
        description: {
          value: "",
          error: null,
        },
        email: {
          value: "",
          error: null,
        },
        role: {
          value: "readonly",
          error: null,
        },
        accountType: {
          value: "",
          error: null,
        },
        partnerId: {
          value: "",
          error: null,
        },
        customerId: {
          value: "",
          error: null,
        }
      },
      partners: this.partnersList,
      customers: this.customersList,
      customersSelectList: [],
      roles: this.rolesList,
      accountTypes: this.accountTypeList,
    };
  },
  methods: {
    clearForm() {
      this.$set(this.formData.username, "value", "");
      this.$set(this.formData.description, "value", "");
      this.$set(this.formData.email, "value", "");
      this.$set(this.formData.role, "value", "");
      this.$set(this.formData.partnerId, "value", "");
      this.$set(this.formData.customerId, "value", "");
      this.$set(this.formData.accountType, "value", "");
    },
  },
  created () {
    if (!this.isEditForm) {
      this.$watch(
        () => this.formData.partnerId.value,
        function (val) {
          this.customersSelectList = []; 

          for (const customer of this.customers) {
            if (customer.partnerId === val) {
              this.customersSelectList.push(customer);
            }
          }
        }
      );
      this.$watch(
        () => this.formData.accountType.value,
        function (val) {
          if (val === 'is_global_admin_account_type') {
            this.formData.partnerId.value = '';
            this.formData.customerId.value = '';
          } else if (val === 'is_partner_account_type') {
            this.formData.customerId.value = '';
          }
        }
      );
    }
  },
  mounted() {},
};
</script>
