<template>
  <div>
    <b-row>
      <user-list input-title="Customer accounts" role="customer" :customer-id="customerId" />
    </b-row>
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import UserList from '@/components/user/UserList';

export default {
  name: "customer-accounts",
  components: { UserList },
  data() {
    return {
      customerId: null
    }
  },
  methods: {
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'customer', this.customerId);
    }
  },
  created() {
    this.customerId = this.$route.params.id;

    window.addEventListener('load', this.handleLoadEvent);
  }
};
</script>
