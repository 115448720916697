<template>
  <b-container>
    <b-row class="pb-3">
      <b-col class="col-md-6 col-lg-6">
        <h4>{{ title }}</h4>
      </b-col>
      <b-col class="col-md-d col-lg-6 text-right">
        <b-button
          v-if="user.role == 'admin'"
          size="sm"
          variant="primary"
          v-b-modal.modal-add-user
        >
          Add New User
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col offset-md="6" md="6" class="mb-2">
        <b-form-group label-cols-sm="3" class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          id="users-list"
          striped
          hover
          small
          show-empty
          empty-text="No data available in table"
          :items="users"
          :fields="columns"
          :busy="isBusy"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:cell(partner)="data">
            <router-link :to="{ path: `/partner/${data.item.company.customer.partner.id}/dashboard` }" target="_blank" v-if="data.item.company">
              {{ data.item.company.customer.partner.name }}
            </router-link>
            <div v-else>
              N/A
            </div>
          </template>

          <template v-slot:cell(customer)="data">
            <router-link :to="{ path: `/customer/${data.item.company.customer.id}/dashboard` }" target="_blank" v-if="data.item.company">
              {{ data.item.company.customer.name }}
            </router-link>
            <div v-else>
              N/A
            </div>
          </template>

          <template v-slot:cell(action)="data">
            <font-awesome-icon
              icon="info-circle"
              class="mr-1 action-icon"
              @click="data.toggleDetails"
              v-if="role === 'admin'"
            />
            <font-awesome-icon
              :icon="isUserActive(data.item) ? 'lock' : 'lock-open'"
              v-if="user.role == 'admin'"
              id="toggleStateButton"
              @click="setSelectedUser(data.item)"
              class="mr-1 action-icon"
              :title="isUserActive(data.item) ? 'Disable user' : 'Enable user'"
              v-b-modal.modal-toggle-state
            />
            <font-awesome-icon
              icon="edit"
              v-if="user.role == 'admin'"
              id="editButton"
              @click="setSelectedUser(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-edit-user
            />
            <font-awesome-icon
              icon="trash-alt"
              v-if="user.role == 'admin'"
              id="deleteButton"
              @click="setSelectedUser(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-delete
            />
          </template>

          <template #row-details="data">
            <b-card>
              <b-row>
                <b-col class="col-6">
                  Email: {{ data.item.email }}
                </b-col>
                <b-col class="col-6">
                  Description: {{ data.item.description }}
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>

        <b-row>
          <div class="col-md-6 col-lg-6">
            <label>Show </label>
            <b-form-select v-model="perPage" class="select-xs">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </b-form-select>
            <span>entries</span>
          </div>
          <div class="col-md-6 col-lg-6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="users-list"
              align="right"
              v-bind:hide-goto-end-buttons="true"
            ></b-pagination>
          </div>
        </b-row>

        <b-row>
          <b-modal
            id="modal-edit-user"
            ref="modalEdit"
            title="Edit User"
            v-bind:no-close-on-backdrop="true"
            v-bind:no-close-on-esc="true"
            @shown="handleEditUserModalShown"
          >
            <user-form
              ref="formEdit"
              is-edit-form="true"
              :companies-list="companies"
              :roles-list="roles"
              :account-type-list="accountTypes"
              :role="role"
              @submit.stop.prevent="handleEditSubmit"
            ></user-form>
            <template #modal-footer>
              <div class="w-100 justify-content-between d-flex">
                <b-button
                  variant="primary"
                  class="rounded"
                  size="xs"
                  @click="handleResetPassword"
                >
                  Reset password
                </b-button>

                <b-button-group size="xs">
                  <b-button class="rounded-left" @click="handleEditClose"
                    >Close</b-button
                  >
                  <b-button variant="success" @click="handleEditOk"
                    >Ok</b-button
                  >
                </b-button-group>
              </div>
            </template>
          </b-modal>
        </b-row>

        <b-row>
          <b-modal
            id="modal-delete"
            ref="modalDelete"
            title="Delete User"
            v-bind:no-close-on-backdrop="true"
            v-bind:no-close-on-esc="true"
            @ok="disableUser(selectedUser.id)"
          >
            <p>
              User {{ selectedUser ? selectedUser.username : "" }} will be
              deleted. Do you want to proceed?
            </p>
          </b-modal>
        </b-row>

        <b-row>
          <b-modal
            id="modal-toggle-state"
            ref="modalToggleState"
            title="Toggle user status"
            v-bind:no-close-on-backdrop="true"
            v-bind:no-close-on-esc="true"
            @ok="toggleUserState(selectedUser)"
          >
            <p v-if="isUserActive(selectedUser)">
              Status of user
              {{ selectedUser ? selectedUser.username : "" }} will be changed to
              {{ this.states.inactive }}. User will not be able to login. Do you
              want to proceed?
            </p>
            <p v-else>
              Status of user
              {{ selectedUser ? selectedUser.username : "" }} will be changed to
              {{ this.states.active }}. Do you want to proceed?
            </p>
          </b-modal>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-modal
        id="modal-add-user"
        ref="modalAdd"
        title="New User"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="handleNewOk"
      >
        <user-form
          ref="formNew"

          :partners-list="partners"
          :customers-list="customers"
          :companies-list="companies"
          :roles-list="roles"
          :account-type-list="accountTypes"
          :role="role"
          @submit.stop.prevent="handleNewOk"
        ></user-form>
      </b-modal>
    </b-row>
  </b-container>
</template>

<script>
import UserForm from "@/components/user/UserForm";
import ApiClient from "@/services/ApiClient";
import Auth from "@/services/Auth";

export default {
  name: "user-list",
  components: { UserForm },
  props: {
    inputTitle: {},
    role: {
      required: true,
      validator: function (value) {
        const roles = ["admin", "partner", "customer"];

        return roles.indexOf(value) !== -1;
      },
    },
    partnerId: {},
    customerId: {},
  },
  data() {
    return {
      title: this.inputTitle,
      user: Auth.user,
      users: [],
      companies: [],
      customers: [],
      partners: [],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      selectedUser: null,
      states: {
        active: "active",
        inactive: "disabled",
      },
      roles: [
        { value: "admin", text: "Admin" },
        { value: "readonly", text: "Readonly" },
        { value: "readwrite", text: "Readwrite" },
      ],
      accountTypes: [
        {
          value: "is_global_admin_account_type",
          text: "Global Admin Account Type",
        },
        { value: "is_partner_account_type", text: "Partner Account Type" },
        { value: "is_customer_account_type", text: "Customer Account Type" },
      ],
      columns: [
        {
          key: "username",
          label: "User",
          sortable: true,
        },
        {
          key: "accountType",
          label: "Account Type",
          sortable: true,
          formatter: (accountType) => this.formatAccountType(accountType)
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
          formatter: (role) => role.charAt(0).toUpperCase() + role.slice(1),
        },
        {
          key: "partner",
          label: "Partner",
          sortable: true,
        },
        {
          key: "customer",
          label: "Customer",
          sortable: true,
        },
        {
          key: "state",
          label: "State",
          sortable: true,
        },
        {
          key: "action",
          class: "actions",
          label: "Actions",
        },
      ]
    };
  },
  methods: {
    formatAccountType(accountType) {
      for (const type of this.accountTypes) {
        if (accountType === type.value) {
          return type.text;
        }
      }
      return "N/A";
    },
    disableUser(id) {
      this.$set(this, "isBusy", true);

      ApiClient.deleteUser(id).then(() => {
        this.$set(this, "isBusy", false);

        this.refreshList();
      });
    },
    setSelectedUser(item) {
      this.$set(this, "selectedUser", item);
    },
    handleEditClose() {
      this.$refs.modalEdit.hide();
      this.$refs.formEdit.setFormDataValues(this.selectedUser);
    },
    handleEditUserModalShown() {
      this.$refs.formEdit.setFormDataValues(this.selectedUser);
    },
    handleEditOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, "isBusy", true);
      this.handleEditSubmit();
    },
    handleNewOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.$set(this, "isBusy", true);
      this.handleNewSubmit();
    },
    handleEditSubmit() {
      this.$refs.formEdit.clearFormErrors();

      // submit data
      ApiClient.updateUser(
        this.selectedUser.id,
        this.getUserFormData(this.$refs.formEdit.formData)
      )
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalEdit.hide();
          });

          this.refreshList();
          this.$set(this, "isBusy", false);
        })
        .catch((error) => {
          error.response.data.map((errorItem) => {
            this.$refs.formEdit.setFieldError(
              errorItem.fieldPath,
              errorItem.message
            );
          });
        });
    },
    handleResetPassword() {
      if (this.selectedUser.id === this.user.id) {
        if (confirm('You will be logged out after password reset')) {
          ApiClient
            .resetUserPassword(this.user.id)
            .then(() => {
              localStorage.setItem('passChanged', true);

              Auth.logoutUser();
            })
        }
      } else {
        ApiClient.resetUserPassword(this.selectedUser.id).then(() => {
          const message = "We sent a new password to user email";
          this.$bvToast.toast(message, {
            title: "Check email",
            variant: "success",
            toaster: "b-toaster-bottom-right",
            solid: true,
          });
        });
      }
    },
    getUserFormData(formData) {
      if (this.role === 'admin') {
        return {
          username: formData.username.value,
          description: formData.description.value,
          email: formData.email.value,
          partnerId: formData.partnerId.value,
          customerId: formData.customerId.value,
          accountType: formData.accountType.value,
          role: 'admin',
        };
      } else if (this.role === "partner") {
        return {
          username: formData.username.value,
          description: formData.description.value,
          email: formData.email.value,
          role: formData.role.value,
          partnerId: this.partnerId,
          accountType: "is_partner_account_type",
        }
      } else if (this.role === "customer") {
        return {
          username: formData.username.value,
          description: formData.description.value,
          email: formData.email.value,
          role: formData.role.value,
          customerId: this.customerId,
          accountType: "is_customer_account_type",
        }
      }
    },
    handleNewSubmit() {
      this.$refs.formNew.clearFormErrors();

      // submit data
      ApiClient.createUser(this.getUserFormData(this.$refs.formNew.formData))
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalAdd.hide();
          });

          this.refreshList();
          this.$set(this, "isBusy", false);
        })
        .catch((error) => {
          error.response.data.map((errorItem) => {
            this.$refs.formNew.setFieldError(
              errorItem.fieldPath,
              errorItem.message
            );
          });
        });
    },
    retrieveUsers() {
      ApiClient.findUsers().then((response) => {
        this.users = [];

        for (const user of response.data) {
          if (this.role === "admin") {
            if (user.role === "admin") {
              this.users.push(user);
            }
          } else if (this.role === "partner") {
            if (user.company && user.company.customer.partnerId === this.partnerId && user.company.customer.is_default && user.accountType === "is_partner_account_type") {
              this.users.push(user);
            }
          } else if (this.role === "customer") {
            if (user.company && user.company.customerId === this.customerId && user.accountType === "is_customer_account_type") {
              this.users.push(user);
            }
          }
        }

        this.totalRows = this.users.length;
      });
    },
    retrieveCompanies() {
      ApiClient.findCompanies().then((response) => {
        this.companies = [];
        response.data.forEach((element) => {
          this.companies.push({
            value: element.id,
            text: element.name,
          });
        });
      });
    },
    retrieveCustomers() {
      ApiClient.findCustomers().then((response) => {
        this.customers = [];
        response.data.forEach((element) => {
          this.customers.push({
            value: element.id,
            text: element.name,
            partnerId: element.partnerId
          });
        });
      });
    },
    retrievePartners() {
      if (this.role !== "customer") {
        ApiClient.findPartners().then((response) => {
          this.partners = [];
          response.data.forEach((element) => {
            this.partners.push({
              value: element.id,
              text: element.name,
            });
          });
        });
      }
    },
    refreshList() {
      this.retrieveUsers();
      this.retrievePartners();
      this.retrieveCustomers();
    },
    isUserActive(user) {
      if (!user) return false;

      return user.state === this.states.active;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleUserState(data) {
      const action = this.isUserActive(data) ? "suspend" : "restore";

      this.$set(this, "isBusy", true);

      // submit data
      ApiClient.dispatchUserAction(this.selectedUser.id, action)
          .then(() => {
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs.modalToggleState.hide();
            });

            this.refreshList();
            this.$set(this, "isBusy", false);
          });
    },
  },
  watch: {
    perPage: function () {
      this.refreshList();
    }
  },
  created() {
    if (this.role === "admin") {
      this.columns = [
        {
          key: "username",
          label: "User",
          sortable: true,
        },
        {
          key: "accountType",
          label: "Account Type",
          sortable: true,
          formatter: (accountType) => this.formatAccountType(accountType)
        },
        {
          key: "partner",
          label: "Partner",
          sortable: true,
        },
        {
          key: "customer",
          label: "Customer",
          sortable: true,
        },
        {
          key: "state",
          label: "State",
          sortable: true,
        },
        {
          key: "action",
          class: "actions",
          label: "Actions",
        },
      ]
    } else {
      this.columns = [
        {
          key: "username",
          label: "User",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
          formatter: (role) => role.charAt(0).toUpperCase() + role.slice(1),
        },
        {
          key: "state",
          label: "State",
          sortable: true,
        },
        {
          key: "action",
          class: "actions",
          label: "Actions",
        },
      ]
    }
  },
  mounted() {
    this.refreshList();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 450px;
  margin: auto;
}
</style>
