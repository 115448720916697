<template>
  <div></div>
</template>

<script>
import Auth from "@/services/Auth";
import router from "@/router";

export default {
  name: "dashboard",
  beforeCreate() {
    if (Auth.user) {
      if (Auth.user.accountType === 'is_global_admin_account_type') {
        router.push('/admin/dashboard');
      } else if (Auth.user.accountType === 'is_partner_account_type') {
        router.push(`/partner/${Auth.user.company.customer.partnerId}/dashboard`);
      } else if (Auth.user.accountType === 'is_customer_account_type') {
        router.push(`/customer/${Auth.user.company.customerId}/dashboard`);
      }
    }
  },
};
</script>
