import ApiClient from '@/services/ApiClient';
import EventBus from '@/services/EventBus';
import consts from '@/consts';
import router from "@/router";

export default {
  user: null,
  idleTimeout: null,
  init() {
    this.user = this.getUserFromStorage();
    
    this.startIdleTimeout();
    this.listenIdleTimeoutReset();

    // remove current user data and redirect to login form
    EventBus.$on(consts.EVENT_WRONG_AUTH_DATA, this.logoutUser.bind(this));
  },
  setUser(userData) {
    this.user = Object.assign({}, userData);

    // have to update authdata if username was changed
    this.updateAuthData();
    this.saveUserToStorage(this.user);
  },
  deleteUser() {
    this.user = null;
  },
  saveUserToStorage(userData) {
    localStorage.setItem("user", JSON.stringify(userData));
  },
  deleteUserFromStorage() {
    localStorage.removeItem("user");
  },
  getUserFromStorage() {
    return JSON.parse(localStorage.getItem("user"));
  },
  updateAuthData() {
    let password = atob(this.user.authdata).split(':')[1];
    let newAuthdata = btoa(this.user.username + ':' + password)

    this.user.authdata = newAuthdata;
  },
  loginUser(username, password) {
    return new Promise((resolve) => {
      ApiClient
        .loginUser(username, password)
        .then(response => {
          let user = Object.assign({
            authdata: btoa(username + ':' + password)
          }, response.data);

          this.setUser(user);
          this.saveUserToStorage(user);

          EventBus.$emit(consts.EVENT_LOGIN_SUCCESS, user);

          resolve(this.user);
        })
        .catch(error => {
          EventBus.$emit(consts.EVENT_APP_ERROR, error.response.data);
        });
    });
  },
  logoutUser() {
    this.deleteUser();
    this.deleteUserFromStorage();

    EventBus.$emit(consts.EVENT_LOGOUT_SUCCESS);

    router.push('/login');
  },
  startIdleTimeout() {
    this.idleTimeout = setTimeout(() => {
      this.logoutUser();
    }, consts.SESSION_IDLE_TIMEOUT);
  },
  resetIdleTimeout() {
    clearTimeout(this.idleTimeout);

    this.startIdleTimeout();
  },
  listenIdleTimeoutReset() {
    document.addEventListener('click', this.resetIdleTimeout.bind(this));
    // document.addEventListener('keyup', this.resetIdleTimeout.bind(this));
  }
}