<template>
  <div class="row">
    <div class="col-md-4">
      <h4 class="my-3">{{ user.username }} Profile</h4>
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Username"
          label-for="username-input"
        >
          <b-form-input
            id="username-input"
            v-model="user.username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="email-input"
        >
          <b-form-input
            id="email-input"
            v-model="user.email"
            required
          ></b-form-input>
        </b-form-group>
        <div role="group" class="form-group">
          <b-form-checkbox
            id="notifications-input"
            v-model="user.receiveNotifications"
            required
          >Receive notifications</b-form-checkbox>
        </div>
        <b-button type="submit" variant="primary">Save Changes</b-button>
      </b-form>

      <hr class="mt-5">
      <button class="btn btn-primary" @click="handleResetPasswordClick">Reset Password</button>
      <button class="ml-4 btn btn-primary" v-b-modal.modal-change-password>Change Password</button>
      <p class="text-secondary mt-1"><small>You will be logged out after password change</small></p>
    </div>
    <b-row>
      <b-modal
        id="modal-change-password"
        ref="modalPassword"
        title="Change password"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="handleChangePasswordClick"
      >
        <p class="text-secondary mt-1"><small>You will be logged out after password change</small></p>
        <b-form @submit.stop.prevent="handleChangePasswordClick" ref="formPassword">
          <b-form-group
            label="Password"
            label-for="password-input"
          >
            <b-form-input
              id="password-input"
              v-model="formData.password.value"
              type="password"
              :class="{'border-danger': formData.password.error}"
            ></b-form-input>
            <div class="text-danger" v-if="formData.password.error">{{ formData.password.error }}</div>
          </b-form-group>
          <b-form-group
            label="New Password"
            label-for="new-password-input"
          >
            <b-form-input
              id="new-password-input"
              v-model="formData.newPassword.value"
              type="password"
              :class="{'border-danger': formData.newPassword.error}"
            ></b-form-input>
            <div class="text-danger" v-if="formData.newPassword.error">{{ formData.newPassword.error }}</div>
          </b-form-group>
          <b-form-group
            label="Confirm New Password"
            label-for="confirm-new-password-input"
          >
            <b-form-input
              id="confirm-new-password-input"
              v-model="formData.confirmNewPassword.value"
              type="password"
              :class="{'border-danger': formData.confirmNewPassword.error}"
            ></b-form-input>
            <div class="text-danger" v-if="formData.confirmNewPassword.error">{{ formData.confirmNewPassword.error }}</div>
          </b-form-group>
        </b-form>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import Auth from "@/services/Auth";
import ApiClient from "@/services/ApiClient";
import formsCommonMixin from "../mixins/formsCommonMixin";

export default {
  name: "user-profile",
  mixins: [formsCommonMixin],
  data() {
    return {
      user: null,
      formData: {
        password: {
          value: '',
          error: null
        },
        newPassword: {
          value: '',
          error: null
        },
        confirmNewPassword: {
          value: '',
          error: null
        },
      }
    }
  },
  methods: {
    handleSubmit() {
      ApiClient
        .updateUser(this.user.id, {
          username: this.user.username,
          email: this.user.email,
          receiveNotifications: this.user.receiveNotifications
        })
        .then(() => {
          Auth.setUser(this.user);

          this.$bvToast.toast('Data successfully saved', {
            title: 'Data saved',
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
            solid: true
          });
        })
    },
    handleResetPasswordClick() {
      if (confirm('You will be logged out after password reset')) {
        ApiClient
          .resetUserPassword(this.user.id)
          .then(() => {
            localStorage.setItem('passChanged', true);

            Auth.logoutUser();
          })
      }
    },
    handleChangePasswordClick(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.clearFormErrors();
      ApiClient
        .changeUserPassword(this.user.id, {
          password: this.formData.password.value,
          newPassword: this.formData.newPassword.value,
          confirmNewPassword: this.formData.confirmNewPassword.value
        })
        .then(() => {
          this.$refs.modalPassword.hide();
          Auth.logoutUser();
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    }
  },
  created() {
    this.$set(this, 'user', Auth.user);
  }
}
</script>