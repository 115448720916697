<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-form-group
      label="Name"
      label-for="name-input">
      <b-form-input
        id="name-input"
        v-model="formData.name.value"
        :class="{'border-danger': formData.name.error}"
      ></b-form-input>
      <div class="text-danger" v-if="formData.name.error">{{ formData.name.error }}</div>
    </b-form-group>
    <b-form-group
      label="Description"
      label-for="description-input">
      <b-form-input
        id="description-input"
        v-model="formData.description.value"
        :class="{'border-danger': formData.description.error }"
      ></b-form-input>
      <div class="text-danger" v-if="formData.description.error">{{ formData.description.error }}</div>
    </b-form-group>
  </b-form>
</template>
 
<script>
import formsCommonMixin from "../../mixins/formsCommonMixin";
 
export default {
  name: "customer-form",
  mixins: [formsCommonMixin],
  data() {
    return {
      formData: {
        name: {
          value: '',
          error: null
        },
        description: {
          value: '',
          error: null
        }
      }
    }
  },
  methods: {
    clearForm() {
      this.$set(this.formData.name, 'value', '');
      this.$set(this.formData.description, 'value', '')
    }
  },
  mounted() {
  }
};
</script>
