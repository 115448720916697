<template>
  <div class="row">
    <div class="col-md-4 offset-md-4">
      <img src="/img/antaira-logo-slogan.png" width="100%" class="mb-4 mt-5" /> 
      <b-form class="mt-5">
        <b-form-group id="input-group-1" label="Login" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Password" label-for="input-2">
          <b-form-input
            id="input-2"
            type="password"
            v-model="password"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" size="sm" class="float-right" @click.prevent="handleLoginClick">Login</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
  import Auth from '@/services/Auth';
  import router from "@/router";

  export default {
    name: "login",
    data() {
      return {
        username: '',
        password: ''
      }
    },
    beforeCreate() {
      if (Auth.user) {
        if (Auth.user.accountType === 'is_global_admin_account_type') {
          router.push('/admin/dashboard');
        } else if (Auth.user.accountType === 'is_partner_account_type') {
          router.push(`/partner/${Auth.user.company.customer.partnerId}/dashboard`);
        } else if (Auth.user.accountType === 'is_customer_account_type') {
          router.push(`/customer/${Auth.user.company.customerId}/dashboard`);
        }
      }
    },
    mounted() {
      let passChanged = localStorage.getItem('passChanged');

      if (passChanged) {
        this.$bvToast.toast('Please check your email for new password', {
          title: 'Password changed',
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
          solid: true
        });

        localStorage.removeItem('passChanged');
      }
    },
    methods: {
      handleLoginClick() {
        Auth
          .loginUser(this.username, this.password)
          .then(user => {
            if (user) {
              if (user.accountType === 'is_global_admin_account_type') {
                router.push('/admin/dashboard');
              } else if (user.accountType === 'is_partner_account_type') {
                router.push(`/partner/${user.company.customer.partnerId}/dashboard`);
              } else if (user.accountType === 'is_customer_account_type') {
                router.push(`/customer/${user.company.customerId}/dashboard`);
              }
            }
          });
      }
    }
  }
</script>