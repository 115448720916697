import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faTrashAlt, faEdit, faCopy, faExternalLinkAlt, faLock, faLockOpen, faChartLine, faEye, faFileCsv, faFilePdf, faInfoCircle, faKey, faCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faTrashAlt, faEdit, faCopy, faExternalLinkAlt, faLock, faLockOpen, faChartLine, faEye, faFileCsv, faFilePdf, faInfoCircle, faKey, faCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon)
