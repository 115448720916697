<template>
  <div>
    <b-row>
      <user-list input-title="Partner accounts" role="partner" :partner-id="partnerId" />
    </b-row>
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import UserList from '@/components/user/UserList';

export default {
  name: "partner-accounts",
  components: { UserList },
  data() {
    return {
      partnerId: null
    }
  },
  methods: {
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'partner', this.partnerId);
    }
  },
  created() {
    this.partnerId = this.$route.params.id;

    window.addEventListener('load', this.handleLoadEvent);
  }
};
</script>
