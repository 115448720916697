<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-form-group
      label="Name"
      label-for="name-input"
    >
      <b-form-input
        id="name-input"
        v-model="formData.name.value"
        :class="{'border-danger': formData.name.error}"
      ></b-form-input>
      <div class="text-danger" v-if="formData.name.error">{{ formData.name.error }}</div>
    </b-form-group>
    <b-form-group
      label="Port (supported ranges 50000 - 65000)"
      label-for="port-input"
    >
      <b-form-input
        id="port-input"
        v-model="formData.port.value"
        :class="{'border-danger': formData.port.error}"
      ></b-form-input>
      <div class="text-danger" v-if="formData.port.error">{{ formData.port.error }}</div>
    </b-form-group>
    <b-form-group
      label="Group Routing address"
      label-for="group-subnet-input"
      v-if="isEditForm"
    >
      <b-form-input
        id="group-subnet-input"
        v-model="formData.subnet.value"
        :class="{'border-danger': formData.subnet.error}"
      ></b-form-input>
      <div class="text-danger" v-if="formData.subnet.error">{{ formData.subnet.error }}</div>
    </b-form-group>
    <b-form-group
      label="List Of Current Members Of Group"
      label-for="group-membres-list"
      v-if="isEditForm" >
      <div id="group-membres-list">
        <b-table
          hover
          small
          :items="members"
          :fields="memebers_table_columns">
          <template v-slot:cell(action)="data">
            <font-awesome-icon icon="external-link-alt"
              class="mr-1 action-icon"
              @click="handleEndpointExternalLink(data.item.id)"/>
          </template>
        </b-table>
      </div>
    </b-form-group>
    <b-form-group
      label="Existing Routes"
      label-for="routes-list"
      v-if="isEditForm" >
      <div id="routes-list">
      <b-table
        hover
        small
        :items="routes"
        :fields="columns" >
        <template v-slot:cell(action)="data">
            <font-awesome-icon icon="trash-alt"
              id="disableEnableButton"
              @click="deleteRoute(data.item.id)"
              class="mr-1 action-icon" />
          </template>
        </b-table></div>
    </b-form-group>
    <b-row v-if="isEditForm">
      <b-form-group
        label="Endpoint Name"
        label-for="endpoint-id-input"
        class="col-5" >
        <b-form-select
          id="endpoint-id-input"
          :options="endpoints"
          size="md"
          v-model="routeData.endpointId.value"
        ></b-form-select>
        <div class="text-danger" v-if="routeData.endpointId.error">{{ routeData.endpointId.error }}</div>
      </b-form-group>
      <b-form-group
        label="Subnet/Mask"
        label-for="subnet-input"
        class="col-5" >
        <b-form-input
          id="subnet-input"
          v-model="routeData.subnet.value"
        ></b-form-input>
        <div class="text-danger" v-if="routeData.subnet.error">{{ routeData.subnet.error }}</div>
      </b-form-group>
      <b-col class="col-1 text-right">
        <b-button
          id="add-route-btn"
          size="sm"
          variant="primary"
          @click="addRoute()"
        >ADD</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import formsCommonMixin from "../../mixins/formsCommonMixin";
import ApiClient from "@/services/ApiClient";

export default {
  name: "group-form",
  mixins: [formsCommonMixin],
  props: ['isEditForm', 'groupId', 'inputCustomerId'],
  data() {
    return {
      customerId: null,
      formData: {
        name: {
          value: '',
          error: null
        },
        port: {
          value: '',
          error: null
        },
        subnet: {
          value: '',
          error: null
        }
      },
      columns:[
        {
          key: 'endpoint',
          formatter: endpoint => endpoint.name,
          thStyle: { display: 'none' }
        },
        {
          key: 'netmask',
          thStyle: { display: 'none' }
        },
        {
          thStyle: { display: 'none' },
          key: "action",
          class: "action"
        }
      ],
      memebers_table_columns: [
        {
          key: 'name',
          thStyle: { display: 'none' }
        },
        {
          key: 'action',
          thStyle: { display: 'none' }
        },
        {
          key: 'type',
          thStyle: { display: 'none' },
          formatter: type => { return type == "pc" ? "PC" : "Router"}
        },
        {
          key: 'address',
          thStyle: { display: 'none' }
        }
      ],
      routeData: {
        endpointId: {
          value: '',
          error: null
        },
        groupId: {
          value: this.groupId,
          error: null
        },
        subnet: {
          value: '',
          error: null
        }
      },
      routes: [],
      endpoints: [],
      members: [],
      group: this.groupId
    }
  },
  methods: {
    clearForm() {
      this.$set(this.formData.name, 'value', '');
      this.$set(this.formData.port, 'value', '');
      this.$set(this.formData.subnet, 'value', '');
      this.$set(this.routes, []);
    },
    deleteRoute(id){
      ApiClient
        .deleteRoute(this.group, id)
        .then(() => {
          this.refreshList();
        });
    },
    addRoute(){
      for (let field in this.routeData) {
        this.$set(this.routeData[field], 'error', null);}
      ApiClient
        .createRoute(this.group, this.getRouteData(this.routeData))
        .then(() => {
          this.refreshList();
          for (let field in this.routeData) {
            this.$set(this.routeData[field], 'value', '');}
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$set(this.routeData[errorItem.fieldPath], 'error', errorItem.message);
          });
        });
    },
    getRouteData(routeData){
      return {
        groupId: routeData.endpointId.value || '',
        endpointId: routeData.endpointId.value || '',
        subnet: routeData.subnet.value || ''
      };
    },
    retriveEndpoints() {
      ApiClient
        .findEndpoints()
        .then(response => {
          this.endpoints = [];
          this.members = [];
          response.data.map(element => {
            if (element.groupId == this.group) {
              // add endpoint to list of members
              this.members.push({
                id: element.id,
                name: element.name,
                type: element.type,
                address: "N/A"
              });

              if (element.type == "router") {
                this.endpoints.push({
                  value: element.id,
                  text: element.name
                });
              }
            }
          });
        });
    },
    refreshList(){
      ApiClient.findRoutes(this.group)
        .then(response => {
          this.routes = response.data;
        });
    },
    handleEndpointExternalLink(id) {
      window.open(this.$router.resolve({ path: `/customer/${this.customerId}/dashboard?endpointId=${id}`}).href, "_blank")
    },
  },
  created() {
    this.customerId = this.inputCustomerId;
  },
  mounted() {
    this.customerId = this.inputCustomerId;

    this.retriveEndpoints()
  }
};
</script>