import { jsPDF } from "jspdf";
import { ExportToCsv } from "export-to-csv";
import antairaLogo from "./assets/antaira-logo-slogan.png";

const prepareDate = (inputDate) => {
  const date = new Date(inputDate);
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  return new Date(utcDate)
    .toISOString()
    .split("T")[0]
    .replaceAll("-", ".");
};

export const generatePdfForStatistic = function(data, from, to, instance) {
  const image = (new Image().src = antairaLogo);
  const doc = new jsPDF();

  // Header
  doc.addImage(image, "PNG", 35, 5, 150, 46);
  doc.line(5, 55, 205, 55);

  // Display subject
  doc.setFontSize(16);
  doc.text(`${instance} report of used resources`, 105, 65, {
    align: "center",
  });

  // Display data
  doc.setFontSize(12);
  const messages = [
    `Identifier: ${data.id}`,
    `Name: ${data.name}`,
    `Report period: ${prepareDate(from)} - ${prepareDate(to)}`,
    `TX: ${data.bandwidth.TX}, RX: ${data.bandwidth.RX}, Total: ${data.bandwidth.total}`,
    `Used endpoints: ${data.usedEndpoints}`,
  ];
  let currentY = 75;
  for (const message of messages) {
    doc.text(message, 10, currentY);
    currentY += 8;
  }

  // Footer
  doc.line(5, 285, 205, 285);
  doc.setFontSize(10);
  doc.text(`© ${new Date().getFullYear()} Antaira Copyright`, 105, 290, {
    align: "center",
  });

  // Save file
  doc.save(`${new Date().getTime()}-${data.name}.pdf`);
};

export const generateCsvForStatistic = function(data, from, to, instance) {
  const options = {
    filename: `${new Date().getTime()}-${data.name}`,
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: `${instance} report of used resources`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const rows = [
    {
      id: data.id,
      name: data.name,
      from: prepareDate(from),
      to: prepareDate(to),
      rx: data.bandwidth.RX,
      tx: data.bandwidth.TX,
      total: data.bandwidth.total,
      usedEndpoints: data.usedEndpoints,
    },
  ];

  new ExportToCsv(options).generateCsv(rows);
};

export const generateCsvForLogs = (rows, params) => {
  const options = {
    filename: `${new Date().getTime()}-logs`,
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: `#The last ${rows.length} records (max ${params.limit})`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  for (const row of rows) {
    row.user = JSON.stringify(row.user);
  }

  new ExportToCsv(options).generateCsv(rows);
};
