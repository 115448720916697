<template>
  <div>
    <sys-log role="customer" :customer-id="customerId" />
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import SysLog from '@/components/SysLog';

export default {
  name: "partner-syslog",
  components: { SysLog },
  data() {
    return {
      customerId: ''
    };
  },
  methods: {
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'customer', this.customerId);
    }
  },
  created() {
    this.customerId = this.$route.params.id;

    window.addEventListener('load', this.handleLoadEvent)
  }
};
</script>
