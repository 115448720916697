<template>
  <div>
    <b-row>
      <b-col class="col-md-12">
        <b-row class="pb-3">
          <b-col class="col-md-6 col-lg-6">
            <h4 v-if="user.accountType === 'is_global_admin_account_type'">{{ name }} - Customers List</h4>
            <h4 v-else>Customers List</h4>
          </b-col>
          <b-col class="col-md-d col-lg-6 text-right">
            <b-button
              v-if="user.role=='admin'"
              size="sm"
              variant="primary"
              v-b-modal.modal-add-customer
            >Add New Customer</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset-md="6" md="6" class="mb-2">
            <b-form-group label-cols-sm="3" class="mb-0">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          id="customers-list"
          striped
          hover
          small
          show-empty
          empty-text="No data available in table"
          :items="customers"
          :fields="fields"
          :busy="isBusy"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered" >
          <template v-slot:cell(action)="data">
            <font-awesome-icon icon="chart-line"
              v-if="user.role=='admin'"
              id="statsButton"
              @click="getCustomerStatistic(data.item)"
              class="mr-1 action-icon"/>
            <font-awesome-icon
              :icon="isCustomerActive(data.item) ? 'lock' : 'lock-open'"
              v-if="user.role=='admin'"
              id="toggleStateButton"
              @click="setSelected(data.item)"
              class="mr-1 action-icon"
              :title="isCustomerActive(data.item) ? 'Disable customer' : 'Enable customer'"
              v-b-modal.modal-toggle-state/>
            <font-awesome-icon icon="edit"
              v-if="user.role=='admin'"
              id="editButton"
              @click="setSelected(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-edit-customer/>
            <font-awesome-icon icon="trash-alt"
              v-if="user.role=='admin'"
              id="disableEnableButton"
              :disabled="data.item.is_default"
              @click="setSelected(data.item)"
              class="mr-1 action-icon"
              v-b-modal.modal-delete />
          </template>
          <template v-slot:cell(name)="data">
            <router-link :to="{ path: `/customer/${data.item.id}/dashboard` }" target="_blank">
              {{ data.item.name }}
            </router-link>
          </template>
        </b-table>
        <b-row>
          <div class="col-md-6 col-lg-6">
            <label>Show </label>
            <b-form-select v-model="perPage" class="select-xs">
              <option value=10>10</option>
              <option value=25>25</option>
              <option value=50>50</option>
              <option value=100>100</option>
            </b-form-select>
            <span>entries</span>
          </div>
          <div class="col-md-6 col-lg-6">
            <b-pagination
              v-model="currentPage"
              :total-rows="customers.length"
              :per-page="perPage"
              aria-controls="customers-list"
              align="right"
              v-bind:hide-goto-end-buttons="true"
            ></b-pagination>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-modal
        id="modal-add-customer"
        ref="modalAdd"
        title="New Customer"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="handleNewOk"
      >
        <customer-form ref="formNew" @submit.stop.prevent="handleSubmit"></customer-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        id="modal-edit-customer"
        ref="modalEdit"
        title="Edit Customer"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @shown="handleEditModalShown"
        @ok="handleEditOk" >
        <customer-form ref="formEdit" @submit.stop.prevent="handleEditSubmit"></customer-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        id="modal-delete"
        ref="modalDelete"
        title="Delete Customer"
        v-bind:no-close-on-backdrop="true"
        v-bind:no-close-on-esc="true"
        @ok="disableCustomer(selected.id)">
        <p>Customer {{ selected ? selected.name : '' }} will be deleted. Do you want to proceed?</p>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
          id="modal-customer-stats"
          ref="modalStats"
          title="Customer statistics"
          v-bind:no-close-on-backdrop="true"
          v-bind:no-close-on-esc="true">
        <b-table stacked :items="statistics">
          <template v-slot:cell(bandwidth)="data">
            <b>RX</b> - {{ data.item.bandwidth.RX }}<br>
            <b>TX</b> - {{ data.item.bandwidth.TX }}<br>
            <b>Total</b> - {{ data.item.bandwidth.total }}
          </template>
        </b-table>
        <template #modal-footer>
          <div class="w-100">
            <b-button-group size="sm">
              <b-button variant="success" @click="exportCSV">Export to CSV</b-button>
              <b-button variant="primary" @click="exportPDF">Export to PDF</b-button>
            </b-button-group>
            <b-button
                variant="info"
                size="sm"
                class="float-right"
                @click="hideStatisticModal"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
          id="modal-toggle-state"
          ref="modalToggleState"
          title="Toggle customer status"
          v-bind:no-close-on-backdrop="true"
          v-bind:no-close-on-esc="true"
          @ok="toggleCustomerState(selected)"
      >
        <p v-if="isCustomerActive(selected)">
          Status of customer {{ selected ? selected.username : '' }} will be
          changed to {{ this.states.inactive }}. Do you want to proceed?
        </p>
        <p v-else>
          Status of customer {{ selected ? selected.username : '' }} will be
          changed to {{ this.states.active }}. Do you want to proceed?
        </p>
      </b-modal>
    </b-row>
    <b-row>
      <b-col class="col-lg-12 col-md-12">
        <router-view @refreshData="refreshList"></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import ApiClient from "@/services/ApiClient"
import Auth from '@/services/Auth';
import CustomerForm from '@/components/partner/CustomerForm';
import { generatePdfForStatistic, generateCsvForStatistic } from "@/helpers";

export default {
  name: "customers-list",
  components: { CustomerForm },
  data() {
    return {
      partnerId: null,
      customers: [],
      user: Auth.user,
      name: '',
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "state",
          label: "State",
          sortable: true
        },
        {
          key: "action",
          class: "action",
          label: "Actions"
        }
      ],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      selected: null,
      states: {
        active: 'active',
        inactive: 'disabled'
      },
      statistics: [],
      statsForReport: {}
    };
  },
  methods: {
    disableCustomer(id) {
      this.$set(this, 'isBusy', true);

      ApiClient
        .deleteCustomer(id)
        .then(() => {
          this.$set(this, 'isBusy', false);

          this.refreshList();
        });
    },
    setSelected(item) {
      this.$set(this, 'selected', item);
    },
    handleEditModalShown() {
      this.$refs.formEdit.setFormDataValues(this.selected);
    },
    handleNewOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.$set(this, 'isBusy', true);
      this.handleSubmit();
    },
    handleEditOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$set(this, 'isBusy', true);
      this.handleEditSubmit();
    },
    getFormData(formData) {
      return {
        name: formData.name.value || '',
        description: formData.description.value || '',
        partnerId: this.partnerId
      };
    },
    handleSubmit() {
      this.$refs.formNew.clearFormErrors();
      // submit data
      ApiClient
        .createCustomer(this.getFormData(this.$refs.formNew.formData))
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modalAdd.hide();
          });
          this.refreshList();
          this.$set(this, 'isBusy', false);
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$refs.formNew.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    handleEditSubmit() {
      this.$refs.formEdit.clearFormErrors();
      ApiClient
        .updateCustomer(this.selected.id, this.getFormData(this.$refs.formEdit.formData))
        .then(() => {
          this.$nextTick(() => {
            this.$refs.modalEdit.hide();
          });
          this.refreshList();
          this.$set(this, 'isBusy', false);
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$refs.formEdit.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    retrieveCustomers() {
      ApiClient
        .findCustomers({ partnerId: this.partnerId })
        .then(response => {
          this.customers = response.data;
          this.totalRows = this.customers.length;
        })
    },
    refreshList() {
      this.retrieveCustomers();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPartner() {
      ApiClient
        .getPartner(this.partnerId)
        .then(response => {
          this.name = response.data.name;
        })
    },
    getCustomerStatistic(item) {
      this.statistics = [];

      const date = new Date();
      const from = Date.UTC(date.getFullYear(), date.getMonth(), 1);
      const to = Date.UTC(date.getFullYear(), date.getMonth() + 1, 0);

      ApiClient
        .customerIdStatistic(item.id, { from: from, to: to })
        .then(response => {
          this.statistics = [
            Object.assign(
              { period: `${new Date(from).toISOString().split('T')[0]} - ${new Date(to).toISOString().split('T')[0]}` },
              response.data
            )
          ];
          this.statsForReport = Object.assign({ id: item.id, name: item.name, from: from, to: to }, this.statistics[0]);
          this.$root.$emit("bv::show::modal", "modal-customer-stats");
        });
    },
    exportCSV() {
      generateCsvForStatistic(this.statsForReport, this.statsForReport.from, this.statsForReport.to, "Customer");
    },
    exportPDF() {
      generatePdfForStatistic(this.statsForReport, this.statsForReport.from, this.statsForReport.to, "Customer");
    },
    hideStatisticModal() {
      this.$refs.modalStats.hide()
    },
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'partner', this.partnerId);
    },
    isCustomerActive(customer) {
      if (!customer) return false;

      return customer.state === this.states.active;
    },
    toggleCustomerState(data) {
      const action = this.isCustomerActive(data) ? "suspend" : "restore";
      this.$set(this, 'isBusy', true);

      // submit data
      ApiClient
          .dispatchCustomerAction(this.selected.id, action)
          .then(() => {
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs.modalToggleState.hide();
            });

            this.refreshList();
            this.$set(this, 'isBusy', false);
          });
    }
  },
  watch: {
    'perPage': function() {
        this.refreshList();
    },
  },
  created() {
    this.partnerId = this.$route.params.id;

    window.addEventListener('load', this.handleLoadEvent);
  },
  mounted() {
    this.retrieveCustomers();
    this.getPartner();
  }
};
</script>
