import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Clipboard from 'v-clipboard'
import App from '@/App.vue'
import router from '@/router'
import ApiClient from '@/services/ApiClient'
import Auth from '@/services/Auth'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './plugins/fontawesome'

require('./assets/styles.css')

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Clipboard)

// Auth should be initialized before ApiClient to get current user info
Auth.init();
ApiClient.init();

new Vue({
  router, // inject the router to make whole app router-aware
  render: h => h(App),
}).$mount('#app')
