import axios from "axios";
import EventBus from '@/services/EventBus';
import Auth from '@/services/Auth';
import consts from '@/consts';

export default {
  http: null,

  // service initialization
  init() {
    this.http = axios.create({
      baseURL: location.protocol + '//' + location.hostname + ':' + process.env.VUE_APP_BASE_API_P0RT + '/v2',
      headers: {
        "Content-type": "application/json",
      }
    });

    // add auth data to each request
    this.http.interceptors.request.use(config => {
      if (Auth.user) {
        config.headers.Authorization = `Basic ${Auth.user.authdata}`;
      }

      return config;
    });

    this.http.interceptors.response.use(
      response => response,
      error => {
        if (error.request.status === 401) {
          // handling unauthorized response
          EventBus.$emit(consts.EVENT_WRONG_AUTH_DATA, 'Wrong auth data');
        } else if (error.request.status === 403) {
          // handle Permission denied
          EventBus.$emit(consts.EVENT_APP_ERROR, 'Permission denied');
        } else if (error.request.status === 400) {
          // handle forms validation
          EventBus.$emit(consts.EVENT_APP_ERROR, 'Bad Request');
        } else {
          // handle other server errors
          EventBus.$emit(consts.EVENT_APP_ERROR, 'Server request error');
        }

        return Promise.reject(error);
      }
    );
  },

  // auth
  loginUser(username, password) {
    return this.http.get(`/user/authenticate?username=${username}&password=${password}`);
  },

  // version
  getVersion() {
    const http = axios.create({
      baseURL: location.protocol + '//' + location.hostname + ':' + process.env.VUE_APP_BASE_API_P0RT,
      headers: {
        "Content-type": "application/json",
      }
    });

    return http.get('/version');
  },

  getRandomPort() {
    return this.http.get('/group/randomport')
  },

  // search
  performSearch(searchString) {
    return this.http.get(`/search?searchString=${searchString}`);
  },

  // search
  retrieveLogs(params) {
    return this.http.get(`/log`, { params: params });
  },

  // routes
  findRoutes(group) {
    return this.http.get(`/group/${group}/route`);
  },
  deleteRoute(group, id) {
    return this.http.delete(`/group/${group}/route/` + id);
  },
  createRoute(group, data) {
    return this.http.post(`/group/${group}/route`, data);
  },

  // customer
  createCustomer(data) {
    return this.http.post("/customer", data);
  },
  updateCustomer(id, data) {
    return this.http.put("/customer/" + id, data);
  },
  dispatchCustomerAction(id, action) {
    return this.http.put(`/customer/${id}/?action=${action}`);
  },
  findCustomers(params) {
    return this.http.get("/customer", { params });
  },
  deleteCustomer(id) {
    return this.http.delete("/customer/" + id);
  },
  customerIdStatistic(id, params) {
    return this.http.get(`/customer/${id}/statistics`, { params });
  },

  // company
  createCompany(data) {
    return this.http.post("/company", data);
  },
  updateCompany(id, data) {
    return this.http.put("/company/" + id, data);
  },
  findCompanies() {
    return this.http.get("/company");
  },
  deleteCompany(id) {
    return this.http.delete("/company/" + id);
  },

  // endpoint
  getEndpoint(id) {
    return this.http.get(`/endpoint/${id}`);
  },
  createEndpoint(data) {
    return this.http.post("/endpoint", data);
  },
  updateEndpoint(id, data) {
    return this.http.put("/endpoint/" + id, data);
  },
  findEndpoints(params) {
    return this.http.get("/endpoint", { params });
  },
  deleteEndpoint(id) {
    return this.http.delete("/endpoint/" + id);
  },

  // group
  createGroup(data) {
    return this.http.post("/group", data);
  },
  updateGroup(id, data) {
    return this.http.put("/group/" + id, data);
  },
  dispatchActionGroup(id, action, data) {
    return this.http.put(`/group/${id}?action=${action}`, data)
  },
  findAllGroups(params) {
    return this.http.get("/group?all=true", { params });
  },
  findGroups(params) {
    return this.http.get("/group", { params });
  },
  deleteGroup(id) {
    return this.http.delete("/group/" + id);
  },
  getGroupSubnet(id) {
    return this.http.get(`/group/${id}/subnet`);
  },
  getGroupRoutes(id) {
    return this.http.get(`/group/${id}/route`);
  },
  groupIdStatistic(id, params) {
    return this.http.get(`/group/${id}/statistics`, { params });
  },

  // group settings
  getGroupSettings() {
    return this.http.get('/configuration/group');
  },
  updateGroupSettings(data) {
    return this.http.put(`/configuration/group`, data);
  },

  // user
  createUser(data) {
    return this.http.post("/user", data);
  },
  updateUser(id, data) {
    return this.http.put("/user/" + id, data);
  },
  dispatchUserAction(id, action) {
    return this.http.put(`/user/${id}/?action=${action}`);
  },
  findUsers() {
    return this.http.get("/user");
  },
  deleteUser(id) {
    return this.http.delete("/user/" + id);
  },
  resetUserPassword(userID) {
    return this.http.post(`/user/${userID}/resetpassword`);
  },
  changeUserPassword(id, data) {
    return this.http.post(`/user/${id}/changepassword`, data);
  },

  // partner
  createPartner(data) {
    return this.http.post("/partner", data);
  },
  getPartner(id) {
    return this.http.get(`/partner/${id}`);
  },
  updatePartner(id, data) {
    return this.http.put(`/partner/${id}`, data);
  },
  dispatchPartnerAction(id, action) {
    return this.http.put(`/partner/${id}/?action=${action}`);
  },
  findPartners() {
    return this.http.get("/partner");
  },
  deletePartner(id) {
    return this.http.delete(`/partner/${id}`);
  },
  partnerIdStatistic(id, params) {
    return this.http.get(`/partner/${id}/statistics`, { params });
  },

  // SMTP
  getSMTPSettings() {
    return this.http.get('/configuration/smtp');
  },
  updateSMTPSettings(data) {
    return this.http.put(`/configuration/smtp`, data);
  },
  sendTestMail(data) {
    return this.http.post(`/configuration/smtp/test`, data);
  },

  // Ping Command
  executePingCommand(id, body) {
    return this.http.post(`/group/${id}/command/ping`, body);
  },
  getPingCommand(id) {
    return this.http.get(`/group/command/ping/${id}`);
  },

  // Routing Table
  executeRountingTableCommand(id, body) {
    return this.http.post(`/group/${id}/command/routingtable`, body);
  },
  getRoutingTableCommand(id) {
    return this.http.get(`/group/command/routingtable/${id}`);
  },

  // TraceRoute Command
  executeTraceRouteCommand(id, body) {
    return this.http.post(`/group/${id}/command/traceroute`, body);
  },
  getTraceRouteCommand(id) {
    return this.http.get(`/group/command/traceroute/${id}`);
  },

  // Quick VPN
  confirmQuickVPNRequest(token) {
    return this.http.get(`/confirm/${token}`);
  },
}
