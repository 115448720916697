import Vue from "vue";
import Router from "vue-router";

import Dashboard from "./components/Dashboard.vue";
import Login from "./components/Login.vue";

// admin components
import AdminDashboard from "./components/admin/Dashboard.vue";
import SMTPSettings from "./components/admin/SMTPSettings.vue";
import AdminSyslog from "./components/admin/AdminSyslog.vue";
import AdminAccounts from "./components/admin/AdminAccounts.vue";

// partner components
import PartnerDashboard from "./components/partner/Dashboard.vue";
import PartnerSyslog from "./components/partner/PartnerSyslog.vue";
import PartnerAccounts from "./components/partner/PartnerAccounts.vue";

// customer components
import CustomerDashboard from "./components/customer/Dashboard.vue";
import CustomerSyslog from "./components/customer/CustomerSyslog";
import CustomerAccounts from "./components/customer/CustomerAccounts.vue";

// group components
import PingGroup from "./components/group/PingGroup.vue";
import RTableGroup from "./components/group/RTableGroup.vue";
import TraceRouteGroup from "./components/group/TraceRouteGroup.vue";

// endpoint
import QuickVPNConfirm from "./components/endpoint/QuickVPNConfirm.vue";

// import CompaniesList from "./components/CompaniesList.vue";
// import GroupsList from "./components/GroupsList.vue";
// import CustomersList from "./components/CustomersList.vue";
// import EndpointsList from "./components/EndpointsList.vue";
// import UsersPage from "./components/UsersPage.vue";
import UserProfile from "./components/UserProfile.vue";
import Auth from '@/services/Auth';
import AdminAccounting from '@/components/admin/AdminAccounting'
import PartnerAccounting from '@/components/partner/PartnerAccounting'
import CustomerAccounting from '@/components/customer/CustomerAccounting'

Vue.use(Router);

let router = new Router({
  mode: "hash",
  base: __dirname,
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        isPublic: true
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: UserProfile
    },
    // {
    //   path: "/company",
    //   name: "companies",
    //   component: CompaniesList
    // },
    // {
    //   path: "/group",
    //   name: "groups",
    //   component: GroupsList
    // },
    // {
    //   path: "/customer",
    //   name: "customers",
    //   component: CustomersList
    // },
    // {
    //   path: "/Endpoint",
    //   name: "endpoints",
    //   component: EndpointsList
    // },
    // {
    //   path: "/user",
    //   name: "users",
    //   component: UsersPage
    // },
    {
      path: "/admin/configuration/accounting",
      name: "admin-accounting",
      component: AdminAccounting
    },
    {
      path: "/admin/dashboard",
      name: "admin-dashboard",
      component: AdminDashboard
    },
    {
      path: "/admin/configuration/smtp",
      name: "smtp-settings",
      component: SMTPSettings
    },
    {
      path: "/admin/tools/syslog",
      name: "admin-syslog",
      component: AdminSyslog
    },
    {
      path: "/admin/configuration/accounts",
      name: "admin-accounts",
      component: AdminAccounts
    },
    {
      path: "/partner/:id/dashboard",
      name: "partner-dashboard",
      component: PartnerDashboard,
    },
    {
      path: "/partner/:id/tools/syslog",
      name: "partner-syslog",
      component: PartnerSyslog,
    },
    {
      path: "/partner/:id/configuration/accounts",
      name: "partner-accounts",
      component: PartnerAccounts
    },
    {
      path: "/partner/:id/configuration/accounting",
      name: "partner-accounting",
      component: PartnerAccounting
    },
    {
      path: "/customer/:id/dashboard",
      name: "customer-dashboard",
      component: CustomerDashboard,
      props: (route) => ({ groupId: route.query.groupId, endpointId: route.query.endpointId })
    },
    {
      path: "/customer/:id/tools/syslog",
      name: "customer-syslog",
      component: CustomerSyslog,
    },
    {
      path: "/customer/:id/configuration/accounts",
      name: "customer-accounts",
      component: CustomerAccounts
    },
    {
      path: "/customer/:id/configuration/accounting",
      name: "customer-accounting",
      component: CustomerAccounting
    },
    {
      path: "/group/:id/ping",
      name: "group-ping",
      component: PingGroup,
      props: (route) => ({ customerId: route.query.customerId })
    },
    {
      path: "/group/:id/traceroute",
      name: "group-trace-route",
      component: TraceRouteGroup,
      props: (route) => ({ customerId: route.query.customerId })
    },
    {
      path: "/group/rtables",
      name: "group-rtable",
      component: RTableGroup,
      props: (route) => ({ groupId: route.query.groupId, customerId: route.query.customerId })
    },
    {
      path: "/confirm/:token",
      name: "quick-vpn-confirm",
      component: QuickVPNConfirm
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!to.meta.isPublic && !Auth.user) {
    next({
      path: '/login',
      params: { nextUrl: to.fullPath }
    })
  } else {
    next();
  }
});

export default router;
