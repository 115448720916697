<template>
  <div>
    <b-row class="pb-3">
      <b-col lg="12" md="12">
        <b-card title="SMTP Settings">
          <b-card-text>
            <b-form @submit.stop.prevent="handleSubmit">
              <b-row>
                <!-- Hostname -->
                <b-col>
                  <b-form-group
                    label="Hostname:"
                    label-cols-md="4"
                    label-for="host-input"
                    label-align="left">
                    <b-form-input
                      id="host-input"
                      v-model="formData.hostname.value"
                      :class="{'border-danger': formData.hostname.error}">
                    </b-form-input>
                    <div class="text-danger" v-if="formData.hostname.error">{{ formData.hostname.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Port -->
                <b-col>
                  <b-form-group
                    label="Port:"
                    label-cols-md="4"
                    label-for="port-input"
                    label-align="left">
                    <b-form-input
                      id="port-input"
                      type="number"
                      v-model="formData.port.value"
                      :number="true"
                      :class="{'border-danger': formData.port.error}">
                    </b-form-input>
                    <div class="text-danger" v-if="formData.port.error">{{ formData.port.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Secure -->
                <b-col class="col-lg-6 col-md-12 col-sm-12">
                  <b-form-group
                    label-cols-sm="6"
                    label="Secure:"
                    label-for="secure-input"
                    label-align-sm="left">
                    <b-form-radio-group
                      id="secure-input"
                      v-model="formData.isSecure.value"
                      class="pt-2 text-right"
                      :options="[
                        {text: 'Yes', value: true },
                        {text: 'No', value: false}
                      ]">
                    </b-form-radio-group>
                    <div class="text-danger" v-if="formData.isSecure.error">{{ formData.isSecure.error }}</div>
                  </b-form-group>
                </b-col>

                <!-- Use TLS -->
                <b-col class="col-lg-6 col-md-12 col-sm-12">
                  <b-form-group
                    label-cols-sm="6"
                    label="Use TLS:"
                    label-for="tls-input"
                    label-align-sm="left">
                    <b-form-radio-group
                      id="tls-input"
                      v-model="formData.useTls.value"
                      class="pt-2 text-right"
                      :options="[
                        {text: 'Yes', value: true },
                        {text: 'No', value: false}
                      ]">
                    </b-form-radio-group>
                    <div class="text-danger" v-if="formData.useTls.error">{{ formData.useTls.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Username -->
                <b-col>
                  <b-form-group
                    label="Username:"
                    label-cols-md="4"
                    label-for="username-input"
                    label-align="left">
                    <b-form-input
                      id="username-input"
                      v-model="formData.username.value"
                      :class="{'border-danger': formData.username.error}">
                    </b-form-input>
                    <div class="text-danger" v-if="formData.username.error">{{ formData.username.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Password -->
                <b-col>
                  <b-form-group
                    label="Password:"
                    label-cols-md="4"
                    label-for="smtp-password-input"
                    label-align="left">
                    <b-input-group>
                      <b-form-input
                        id="smtp-password-input"
                        :type="inputType"
                        v-model="formData.password.value"
                        :class="{'border-danger': formData.password.error}">
                      </b-form-input>
                      <b-button @click="handleClickShowPassword">
                        <font-awesome-icon icon="eye"/>
                      </b-button>
                    </b-input-group>
                    <div class="text-danger" v-if="formData.password.error">{{ formData.password.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Email From -->
                <b-col>
                  <b-form-group
                    label="Email From:"
                    label-cols-md="4"
                    label-for="from-input"
                    label-align="left">
                    <b-form-input
                      id="from-input"
                      v-model="formData.emailFrom.value"
                      :class="{'border-danger': formData.emailFrom.error}">
                    </b-form-input>
                    <div class="text-danger" v-if="formData.emailFrom.error">{{ formData.emailFrom.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Send Test Mail button -->
                <b-col class="col-12 col-sm-6 offset-md-6 col-md-3 text-left">
                  <b-button
                    class="btn-block"
                    size="md"
                    variant="primary"
                    @click="handleSendTestMailButton">
                    Send Test Mail
                  </b-button>
                </b-col>

                <!-- Save Changes button -->
                <b-col class="col-12 col-sm-6 col-md-3 text-right">
                  <b-button
                    class="btn-block"
                    size="md"
                    variant="secondary"
                    @click="handleSaveButton">
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
          <div slot="footer">
            <small class="text-muted">Last updated: {{ updatedAt }}</small>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12">
        <b-card title="Group Settings">
          <b-card-text>
            <b-form @submit.stop.prevent="handleGroupSubmit">
              <b-row>
                <!-- Routing adress -->
                <b-col>
                  <b-form-group
                    label="Default Group routing adress:"
                    label-cols-md="4"
                    label-for="host-input"
                    label-align="left">
                    <b-form-input
                      id="host-input"
                      v-model="groupFormData.subnet.value"
                      :class="{'border-danger': groupFormData.subnet.error}">
                    </b-form-input>
                    <div class="text-danger" v-if="groupFormData.subnet.error">{{ groupFormData.subnet.error }}</div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Save Changes button -->
                <b-col class="col-12 col-sm-6 col-md-3 offset-md-9 text-right">
                  <b-button
                    class="btn-block"
                    size="md"
                    variant="secondary"
                    @click="handleGroupSaveButton">
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-text>
          <div slot="footer">
            <small class="text-muted">Last updated: {{ groupUpdatedAt }}</small>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import ApiClient from "@/services/ApiClient";
import formsCommonMixin from "../../mixins/formsCommonMixin";

export default {
  name: "stmp-settings",
  mixins: [formsCommonMixin],
  data() {
    return {
      inputType: 'password',
      formData: {
        hostname: {
          value: '',
          error: ''
        },
        port: {
          value: null,
          error: ''
        },
        isSecure: {
          value: null,
          error: ''
        },
        useTls: {
          value: null,
          error: ''
        },
        username: {
          value: '',
          error: ''
        },
        password: {
          value: '',
          error: ''
        },
        emailFrom: {
          value: '',
          error: ''
        },
      },
      updatedAt: null,
      groupFormData: {
        subnet: {
          value: '',
          error: ''
        },
      },
      groupUpdatedAt: null
    };
  },
  methods: {
    handleClickShowPassword() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    clearForm() {
      this.$set(this.formData.hostname, 'value', '');
      this.$set(this.formData.port, 'value', null);
      this.$set(this.formData.isSecure, 'value', false);
      this.$set(this.formData.isTLS, 'value', true);
      this.$set(this.formData.username, 'value', '');
      this.$set(this.formData.password, 'value', '');
      this.$set(this.formData.emailFrom, 'value', '');
    },
    getFormData(formData) {
      return {
        hostname: formData.hostname.value || '',
        port: formData.port.value || null,
        isSecure: formData.isSecure.value || false,
        useTls: formData.useTls.value || false,
        username: formData.username.value || '',
        password: formData.password.value || '',
        emailFrom: formData.emailFrom.value || ''
      }
    },
    retrieveSMTPSettings() {
      ApiClient
        .getSMTPSettings()
        .then(response => {
          console.log(response);

          this.setFormDataValues(response.data);

          if (response.data && response.data.updatedAt) {
            this.updatedAt = new Date(response.data.updatedAt).toUTCString();
          }
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        })
    },
    handleSaveButton() {
      this.clearFormErrors();
      ApiClient
        .updateSMTPSettings(this.getFormData(this.formData))
        .then(() => {
          EventBus.$emit(consts.EVENT_APP_SUCCESS, 'Changes saved');
          // load new values
          this.retrieveSMTPSettings();
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.setFieldError(errorItem.fieldPath, errorItem.message);
          });
        });
    },
    handleSendTestMailButton() {
      this.clearFormErrors();
      ApiClient
        .sendTestMail(this.getFormData(this.formData))
        .then(response => {
          EventBus.$emit(consts.EVENT_APP_SUCCESS, response.data);
        })
        .catch(error => {
          if (typeof(error.response.data) === "string") {
            EventBus.$emit(consts.EVENT_APP_ERROR, error.response.data);
          } else {
            error.response.data.map(errorItem => {
              this.setFieldError(errorItem.fieldPath, errorItem.message);
            });
          }
        });
    },
    getGroupFormData(formData) {
      return {
        subnet: formData.subnet.value || ''
      }
    },
    retrieveGroupSettings() {
      ApiClient
        .getGroupSettings()
        .then(response => {
          console.log(response);

          for (let field in this.groupFormData) {
            this.$set(this.groupFormData[field], 'value', response.data[field]);
          }

          if (response.data && response.data.updatedAt) {
            this.groupUpdatedAt = new Date(response.data.updatedAt).toUTCString();
          }
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$set(this.groupFormData[errorItem.fieldPath], 'error', errorItem.message);
          });
        })
    },
    handleGroupSaveButton() {
      for (let field in this.groupFormData) {
        this.$set(this.groupFormData[field], 'error', null);
      }
      ApiClient
        .updateGroupSettings(this.getGroupFormData(this.groupFormData))
        .then(() => {
          EventBus.$emit(consts.EVENT_APP_SUCCESS, 'Changes saved');
          // load new values
          this.retrieveGroupSettings();
        })
        .catch(error => {
          error.response.data.map(errorItem => {
            this.$set(this.groupFormData[errorItem.fieldPath], 'error', errorItem.message);
          });
        });
    },
  },
  mounted() {
    this.retrieveSMTPSettings();
    this.retrieveGroupSettings();

    window.addEventListener('load', () => {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'admin');
    });
  }
};
</script>
