<template>
  <div>
    <sys-log role="partner" :partner-id="partnerId" />
  </div>
</template>

<script>
import consts from '@/consts';
import EventBus from '@/services/EventBus';
import SysLog from '@/components/SysLog';

export default {
  name: "partner-syslog",
  components: { SysLog },
  data() {
    return {
      partnerId: ''
    };
  },
  methods: {
    handleLoadEvent() {
      EventBus.$emit(consts.EVENT_CHANGE_VIEW_SCOPE, 'partner', this.partnerId);
    }
  },
  created() {
    this.partnerId = this.$route.params.id;

    window.addEventListener('load', this.handleLoadEvent)
  }
};
</script>
P